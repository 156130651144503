import React from 'react';
import { Link } from 'react-router-dom';
import { menuItemsData } from '../menuItemsData';
import MenuItems from './MenuItems';

function Navbar() {
    return (
        <nav className="desktop-nav w-full ml-5">
            <div className="flex flex-row gap-2 h-8">
                <ul>
                    <li className="menu-items">
                        <Link to="/find-a-travel-expert" className="menu-item-primary text-base md:-mt-1">Find A Travel Expert</Link>
                    </li>
                </ul>
            </div>
            <div className="flex flex-row gap-2 h-8">
                <ul className="menus">
                    {menuItemsData.map((menu, index) => (
                        <MenuItems items={menu} key={index} />
                    ))}
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
