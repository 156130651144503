/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MobileNav from './MobileNav';
import Navbar from './Navbar';
import Logo from './Logo';

function Header() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();

    const [preview, setPreview] = useState(query.get('preview') || 'false');
    return (
        <div className="relative z-50">
            <div className="nav-area">
                <Logo />
                {/* for large screens */}
                <Navbar />
                {/* for small screens */}
                <MobileNav />
            </div>
        </div>
    );
}

export default Header;
