/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

const API = '';

const getAgencyInfo = ({ agencyId, preview }) => fetch(`${API}/agency/getAgencyInfo?agencyId=${agencyId}&preview=${preview}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchAgencies = ({
    agencyState,
    industry,
    technology,
    language,
    agencyId,
    latitude,
    longitude,
    radius,
    rows,
}) => fetch(`${API}/agency/getAgencies?AgencyState=${agencyState}
        &Industry=${encodeURIComponent(industry)}
        &Technology=${encodeURIComponent(technology)}
        &Language=${encodeURIComponent(language)}
        &AgencyId=${encodeURIComponent(agencyId)}
        &Latitude=${encodeURIComponent(latitude)}
        &Longitude=${encodeURIComponent(longitude)}
        &Radius=${radius}
        &Rows=${rows}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchFaceted = ({
    state,
    tech,
    industry,
    language,
    type,
}) => fetch(`${API}/agency/${type}`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            state,
            tech,
            industry,
            language,
        }),
    })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchStates = () => fetch(`${API}/agency/getStates`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getAddlContent = ({ profileId, contentId }) => fetch(`${API}/agency/GetAddlContentById?profileId=${profileId}&contentId=${contentId}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getTestimonial = ({ profileId, randomize }) => fetch(`${API}/agency/GetAgencyTestimonial?profileId=${profileId}&randomize=${randomize}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const fetchIndustry = () => fetch(`${API}/agency/GetIndustry`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));


const getProfileAgents = ({ profileId, isEvpAgent }) => fetch(`${API}/agency/GetProfileAgents?profileId=${profileId}&isEvpAgent=${isEvpAgent}`, { method: 'GET' })
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const getIpLocation = ({ myIp }) => fetch(`${API}/customer/GetIPLocation?myIp=${myIp}`, {
    method: 'GET',
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendEmailToAgency = (data) => fetch(`${API}/customer/sendEmail`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

const sendPhoneToAgency = (data) => fetch(`${API}/customer/sendPhone`, {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})
    .then((response) => response.json())
    .catch(() => ({
        error: {
            message: 'Unknown error',
        },
    }));

export default {
    getAgencyInfo,
    fetchAgencies,
    fetchFaceted,
    fetchStates,
    getAddlContent,
    getTestimonial,
    fetchIndustry,
    getProfileAgents,
    getIpLocation,
    sendEmailToAgency,
    sendPhoneToAgency,

};
