import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PiHandshakeLight, PiLightbulbFilament, PiUserFocusLight } from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';

function Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Travel Leaders Business - Simplifying The Complexities of Business Travel</title>
                <meta name="title" content="Travel Leaders Business - Simplifying The Complexities of Business Travel" />
                <meta name="description" content="At Travel Leaders, we understand the demands of running a successful business. Our extensive resources and industry influence deliver the results you deserve and enable the creation of a travel program tailored to meet your business needs." />
            </Helmet>
            <Hero
                title="Simplifying The Complexities"
                upperText="Business Travel Experts"
                lowerText="of Business Travel"
                fileName="/media/hero-v2.mp4"
                height="500px"
                fileType="video"
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <InfoBar
                    text="At Travel Leaders, we understand the demands of running a successful business. We understand the needs of modern business travelers, and we are available around the clock to ensure you are never left stranded. Our extensive resources and industry influence deliver the results you deserve and a travel program tailored to your business."
                    icon="check"
                    iconPosition="left"
                />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-2 my-8 md:mx-20">
                    <div
                        className="p-8 text-white"
                        style={{ background: 'url(\'/images/bg-home-difference1.webp\')', backgroundSize: 'cover' }}
                    >
                        <div className="text-4xl pb-4">
                            <PiHandshakeLight />
                        </div>
                        <h2 className="font-bold uppercase">Travel Leaders Difference</h2>
                        <p>Our industry relationships put your business ahead of the curve. We&apos;ll get the best possible value for your dollar to maximize your travel budget.</p>
                    </div>
                    <div
                        className="p-8 text-white"
                        style={{ background: 'url(\'/images/bg-home-peace1.webp\')', backgroundSize: 'cover' }}
                    >
                        <div className="text-4xl pb-4">
                            <PiLightbulbFilament />
                        </div>
                        <h2 className="font-bold uppercase">Peace of Mind</h2>
                        <p>We&apos;re here 24/7 with complete coverage for your entire trip. We handle the stress of traveling so you can concentrate on what really matters.</p>
                    </div>
                    <div
                        className="p-8 text-white"
                        style={{ background: 'url(\'/images/bg-home-specialized1.webp\')', backgroundSize: 'cover' }}
                    >
                        <div className="text-4xl pb-4">
                            <PiUserFocusLight />
                        </div>
                        <h2 className="font-bold uppercase">Specialized Attention</h2>
                        <p>Our trained agents are experienced in specialties like legal, government, education and more. We apply our insider knowledge so your company travels better for less.</p>
                    </div>
                </div>
                <InfoImageBar
                    text="With locations across the United States, Travel Leaders has the business travel expertise you need. Let Travel Leaders handle your travel so you can focus on your business."
                    icon="check"
                    imageUrl="/images/home.webp"
                    imageAlt="Bike at the beach"
                />
                <FeaturedAgency />
            </div>
        </>
    );
}

export default Home;
