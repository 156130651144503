/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function RiskMgmt() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Business Travel',
            url: '/business',
        },
        {
            label: 'Risk Management',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    return (
        <>
            <Helmet>
                <title>Risk Management - Travel Leaders Business</title>
                <meta name="title" content="Risk Management - Travel Leaders Business" />
                <meta name="description" content="Effectively managing risk in the travel industry is crucial for enhancing safety and optimizing experiences, ultimately boosting search engine visibility and customer trust." />
            </Helmet>
            <Hero
                title="Risk Management"
                upperText=""
                lowerText=""
                fileName="/images/hero-risk-mgmt1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-2 my-8 md:mx-20 h-full flex justify-center items-stretch flex-col md:flex-row">
                        <div className="w-full md:w-1/3">
                            <img loading="lazy" src="/images/RM-luggage.webp" alt="Person pulling carry-on luggage through an airport" className="" />
                        </div>
                        <div className="w-full md:w-2/3 bg-blue-50">
                            <InfoBar
                                icon="target"
                                iconPosition="top"
                                text={`<h2 class="block uppercase font-bold">Creating Peace of Mind for travelers, worldwide</h2>
                                       <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                        GuardianCare gives you the powerful assistance and innovative technology you need to keep your travelers updated and accounted for worldwide. Powered by our partners at Onsolve, GuardianCare combines reliable pre-trip risk advisories, global alerts, an interactive traveler dashboard and an optional integration with the Connect app — all through one integrated platform.`}
                                customCSS=""
                            />
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2">
                            <h3 className="font-lg font-bold my-2">The standard program includes: </h3>
                            <ul
                                className="list-outside ml-6"
                                style={{ listStyleType: 'circle' }}
                            >
                                <li className="py-1">Pre-trip risk advisory information and global alerts sent to travelers and travel managers</li>
                                <li className="py-1">Interactive dashboard displaying traveler locations and global alerts</li>
                                <li className="py-1">Up-to-date COVID-19 information</li>
                                <li className="py-1">Emailed traveler alerts based on proximity</li>
                                <li className="py-1">Optional integration with the Connect Plus app</li>
                            </ul>
                            <h3 className="font-lg font-bold my-2">Additional premium services include: </h3>
                            <ul
                                className="list-outside ml-6"
                                style={{ listStyleType: 'circle' }}
                            >
                                <li className="py-1">Facilities integration with facility alerts</li>
                                <li className="py-1">Mobile app for travelers to share location and safety status</li>
                                <li className="py-1">Mobile app for non-travelers</li>
                                <li className="py-1">Emergency response services</li>
                                <li className="py-1">Mass notifications for all employees</li>
                                <li className="py-1">Insurance</li>
                                <li className="py-1">Executive protection</li>
                                <li className="py-1">Evacuation services</li>
                            </ul>
                        </div>
                        <div className="w-full md:w-1/2 bg-gray-800 md:bg-transparent">
                            <img
                                loading="lazy"
                                src="/images/RiskMgmt-Quote.webp"
                                alt="Customer quote in regards to the risk management program"
                                className="ml-auto"
                            />
                        </div>
                    </div>
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default RiskMgmt;
