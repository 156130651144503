/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';

function Customers() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Customers',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    const customerCard = (title, image, url) => (
        <div className="w-full h-full relative bg-gray-800 overflow-hidden text-white hover:text-red-500 transition-colors duration-500">
            <a href={`/customers/${url}`}>
                <img
                    loading="lazy"
                    src={`/images/${image}`}
                    alt={title}
                    className="h-full w-full object-cover hover:opacity-50 transition-all duration-500 hover:scale-105 transform ease-in-out duration-1000"
                />
                <h2 className="block p-4 uppercase text-lg font-medium absolute bottom-0 w-full bg-gray-800 bg-opacity-70">
                    {title}
                </h2>
            </a>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Customers - Travel Leaders Business</title>
                <meta name="title" content="Customers - Travel Leaders Business" />
                <meta name="description" content="At Travel Leaders Business we handle all typs of Customers, from construction and engineering to technology companies, we handle all types of business travel." />
            </Helmet>
            <Hero
                title="Customers"
                upperText=""
                lowerText=""
                fileName="/images/hero-customers1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20">
                        <h2 className="block mb-2 uppercase text-lg font-medium">Featured Customer Industries</h2>
                        When you work with a Travel Leaders travel management company, you&apos;re with an expert. Our travel management companies have extensive experience managing the unique travel needs of our clients in the following industries.
                    </div>
                </div>
                <div className="my-4">
                    <div className="mx-2 my-8 md:mx-20 grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center items-center">
                        {customerCard('Construction & Engineering', 'cust_construction.webp', 'construction-engineering')}
                        {customerCard('Education', 'cust_education.webp', 'education')}
                        {customerCard('Energy/Oil/Gas', 'cust_energy.webp', 'energy')}
                        {customerCard('Financial Services', 'cust_financial.webp', 'finance')}
                        {customerCard('Government', 'cust_government.webp', 'government')}
                        {customerCard('Healthcare', 'cust_healthcare.webp', 'healthcare')}
                        {customerCard('Manufacturing', 'cust_manufacturing.webp', 'manufacturing')}
                        {customerCard('Media & Entertainment', 'cust_media.webp', 'media')}
                        {customerCard('Non-Profit', 'cust_nonprofit.webp', 'non-profit')}
                        {customerCard('Pharmaceutical Industry', 'cust_pharma.webp', 'pharmaceutical')}
                        {customerCard('Professional services', 'cust_professional.webp', 'professional')}
                        {customerCard('Technology', 'cust_tech.webp', 'technology')}
                    </div>
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default Customers;
