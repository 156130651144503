import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import {
    PiSealCheckLight,
    PiHeadset,
    PiChecks,
    PiGraduationCap,
} from 'react-icons/pi';
import Button from './elements/Button';

function InfoImageBar({
    title,
    text,
    imageUrl,
    imageAlt,
    imageAsBG,
    icon,
    iconPosition,
    btnIcon,
    btnText,
    btnLink,
    inverted,
}) {
    const history = useHistory();
    const goToUrl = (link) => {
        history.push(link);
    };
    return (
        <div className="mx-2 my-8 md:mx-20 relative text-center bg-white">
            <img loading="lazy" src={imageUrl} alt={imageAlt} className="w-full" />
            <div className={`flex  ${iconPosition === 'top' ? 'flex-col' : 'flex-row'} flex-nowrap relative ${imageAsBG === true ? 'md:absolute bottom-0 md:-mt-24' : 'mt-0'} w-full p2 md:p-8  ${inverted === true ? 'bg-gray-800 text-white' : 'bg-blue-50'} bg-opacity-90`}>
                {iconPosition === 'top' ? (
                    icon === 'headset' ? (
                        <div className="text-4xl text-red-700 p-4 md:p-0 md:pb-4">
                            <PiHeadset />
                        </div>
                    ) : icon === 'checks' ? (
                        <div className="text-4xl text-red-700 p-4 md:p-0 md:pb-4">
                            <PiChecks />
                        </div>
                    ) : <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                ) : ''}
                <div className="px-4 md:pr-8 text-4xl text-red-700 flex justify-center items-center">
                    {icon === 'check' ? (<PiSealCheckLight />) : ''}
                    {icon === 'graduationCap' ? (<PiGraduationCap />) : ''}
                </div>
                <div className="py-2 px-4 md:px-0 md:py-0 w-full text-left">
                    {title !== '' ? (
                        <h2 className="block font-semibold uppercase py-2 tracking-wide">
                            {title}
                        </h2>
                    ) : ''}
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    {btnLink ? (
                        <div className="block py-4 w-48">
                            <Button
                                title={btnText}
                                onClick={() => goToUrl(btnLink)}
                                icon={btnIcon}
                                inverted={inverted}
                            />
                        </div>
                    ) : ''}
                </div>
            </div>
        </div>
    );
}

InfoImageBar.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    imageAsBG: PropTypes.bool,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    btnIcon: PropTypes.string,
    btnText: PropTypes.string,
    btnLink: PropTypes.string,
    inverted: PropTypes.bool,

};

InfoImageBar.defaultProps = {
    title: '',
    text: 'This is a placeholder',
    imageAlt: '',
    imageAsBG: true,
    icon: '',
    iconPosition: '',
    btnIcon: '',
    btnText: '',
    btnLink: '',
    inverted: false,
};

export default InfoImageBar;
