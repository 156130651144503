/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';
import Button from '../components/elements/Button';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function Business() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
            {
                label: 'Business Travel',
            },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    const backToTop = <div className="mx-4 md:mx-20 text-right -mt-8 "><span className="text-gray-800 hover: hover:text-red-700 cursor-pointer" onClick={() => goToAnchor('topics')}>Back to Topics</span></div>;
    return (
        <>
            <Helmet>
                <title>Business Travel - We are your Ally in Business Travel</title>
                <meta name="title" content="Business Travel - We are your Ally in Business Travel" />
                <meta name="description" content="At Travel Leaders, we understand the demands of running a successful business. Our extensive resources and industry influence deliver the results you deserve and enable the creation of a travel program tailored to meet your business needs." />
            </Helmet>
            <Hero
                title="Business Travel"
                upperText=""
                lowerText=""
                fileName="/images/hero-plane.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        title="Your ally in Business Travel"
                        text="We’re a lean team without a huge bureaucracy, which lets us act swiftly to address opportunities and collaborate on solutions to build the path forward together. Our group takes the time to learn about client needs and pain points in order to deliver customized travel solutions. We analyze travel spending, market trends and traveler behavior to identify inefficiencies and provide cost savings so that you can focus on your business."
                        icon=""
                        iconPosition="top"
                    />
                </div>
                <div className="my-4" id="topics">
                    <div className="mx-2 my-8 md:mx-20 relative text-center bg-white">
                        <div
                            className="flex  flex-col flex-nowrap relative w-full p2 md:p-8  bg-gray-800 text-white"
                            style={{ background: 'url(\'/images/bg-business.webp\')', backgroundSize: 'cover' }}
                        >
                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                            <div className="py-2 px-4 md:px-0 md:py-0 w-full text-left">
                                <h3 className="block font-semibold uppercase py-2 tracking-wide">
                                    Click on the topics below to learn more
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center">
                                    <div className="w-full md:px-20">
                                        <Button
                                            title="Global Travel Fulfillment"
                                            onClick={() => goToAnchor('global')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full md:px-20">
                                        <Button
                                            title="Program Management"
                                            onClick={() => goToAnchor('program')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full md:px-20">
                                        <Button
                                            title="Risk Management"
                                            onClick={() => goToAnchor('risk')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full md:px-20">
                                        <Button
                                            title="Traveler Experience"
                                            onClick={() => goToAnchor('traveler')}
                                            inverted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4" id="global">
                    <InfoImageBar
                        title="Global Travel Fulfillment"
                        text="Our global network individually selects each local partner based on high operational standards. All are experienced, seasoned business travel experts with a top-five local market ranking. This level of service ensures your travelers are policy-compliant and protected around the world, while your data is being actively managed and accessible for your comprehensive reporting requirements."
                        iconPosition="top"
                        imageUrl="/images/GlobalTravelFullfillment.webp"
                        imageAsBG={false}
                        btnIcon="link"
                        btnText="Learn More"
                        btnLink="/business/global-services"
                    />
                    {backToTop}
                </div>
                <div className="my-4" id="program">
                    <InfoImageBar
                        title="Program Management"
                        text="Managed travel services get you the best value while still ensuring employee satisfaction with your travel program.<p>We’re a responsive partner who can customize the services you need. When we meet with prospective clients, we gain insights into the company’s culture and learn what is important to their executives and travelers. Our team of experienced travel advisors works tirelessly to transform your company’s business travel into an efficient operation.</p>"
                        iconPosition="top"
                        imageUrl="/images/ProgramMgmt.webp"
                        btnIcon="headset"
                        btnText="Learn More"
                        btnLink="/business/program-management"
                        inverted
                    />
                    {backToTop}
                </div>
                <div className="my-4" id="risk">
                    <InfoImageBar
                        title="Risk Management"
                        text="GuardianCare gives you the powerful assistance and innovative technology you need to keep your travelers updated and accounted for worldwide. Powered by our partners at Onsolve, GuardianCare combines reliable pre-trip risk advisories, global alerts, an interactive traveler dashboard and an optional integration with the Connect app — all through one integrated platform."
                        iconPosition="top"
                        imageUrl="/images/RiskMgmt.webp"
                        imageAsBG={false}
                        btnIcon="bell"
                        btnText="Learn More"
                        btnLink="/business/risk-management"
                    />
                    {backToTop}
                </div>
                <div className="my-4" id="traveler">
                    <InfoImageBar
                        title="Traveler Experience"
                        text="We are now living in an era where uncertainty is the rule, not the exception. At Travel Leaders we take a holistic approach to ensure your travelers are getting the support they need. We make certain that your employees are provided with the right support, technology, and travel protection they require so they can focus on their business at hand."
                        iconPosition="top"
                        imageUrl="/images/TrvlrExperience.webp"
                        btnIcon="plane"
                        btnText="Learn More"
                        btnLink="/business/traveler-experience"
                        inverted
                    />
                    {backToTop}
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default Business;
