/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiKey,
    PiShieldCheck,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function OnlineBookingTools() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
            url: '/technology',
        },
        {
            label: 'Online Booking Tools',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    const titleBar = (title, image) => (
        <div
            className="flex flex-row mx-4 my-8 md:mx-20 p-4 text-white text-center text-3xl uppercase justify-center bg-gray-800"
            style={{ background: `url('/images/${image}')`, backgroundSize: 'cover' }}
        >
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Online Booking Tools - Travel Leaders Business</title>
                <meta name="title" content="Online Booking Tools - Travel Leaders Business" />
                <meta name="description" content="Elevate your business travel experience with our efficient online booking tools, tailored to streamline arrangements and maximize productivity for every trip." />
            </Helmet>
            <Hero
                title="Online Booking Tools"
                upperText=""
                lowerText=""
                fileName="/images/hero-online-booking-tools1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`Travel Leaders offers our business clients special negotiated rates for leading online booking tools, Concur and Deem.
                               Our integration with these technologies enables our clients to secure business travel at any time of the day or night.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <div className="my-4">
                    {titleBar('Self-Booking Technology', 'TitleSelfBookingTech.webp')}
                    <InfoBar
                        icon="seal"
                        iconPosition="left"
                        text={`Self-booking technology allows users to compare options within the search results.
                               An industry phenomenon known as "visual field" has proven that online users will typically make better purchasing decisions when they can visualize all prices and schedule combinations in an easy-to-understand matrix.
                               Our own data analysis shows an average savings of up to 30% when comparing bookings online versus calling a live agent.
                               Additionally, having to provide documented justification for booking non-compliant travel is an effective deterrent steering most users to book open policy guidelines.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <div className="my-4">
                    {titleBar('Online Booking', 'TitleOnlineBookingTech.webp')}
                    <InfoBar
                        icon="checks"
                        iconPosition="left"
                        text={`All our online booking technologies can be configured to reflect your company's guidelines and rules directing users to policy-compliance options.
                               Exceptions can be managed in pre-approval workflow configurations that are fully automated.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <FeaturedAgency
                    technologyId="27"
                />
            </div>
        </>
    );
}

export default OnlineBookingTools;
