/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import useAppStore from '../contexts/useAppStore';
import BreadCrumbs from '../components/elements/BreadCrumbs';
import Button from '../components/elements/Button';
import SelectInput from '../components/elements/SelectInput';
import { getStateName } from '../utils';

function FindATravelExpert() {
    const {
        getStates,
        getIndustries,
    } = useAppStore();

    const history = useHistory();
    const [selected, setSelected] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    const [selectedState, setSelectedState] = useState('');

    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Find a Travel Expert',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    const onSearch = () => {
        const filters = [];

        if (selectedState !== '') {
            filters.push(`slctState=${selectedState}`);
        }

        history.push(`/agencies?${filters.join('&')}`);
    };

    const barTextColor = 'text-white';
    const barColor = 'bg-gray-800';

    const text1 = () => (
        <div className="w-full my-4">
            <div className="w-full mb-4">
                <img loading="lazy" src="/images/FindAgentTLDifference.webp" alt="Airport Baggage Area" className="w-full" />
            </div>
            We are experts in travel. Our relationships with industry leading organizations provide us with the resources to ensure you are getting the best possible value for your dollar. We have insider knowledge that allows you and your business to get ahead of the curve. Our skilled team of travel industry veterans is here 24-7 to assist you in getting the most out of your travel plans.
        </div>
    );

    const text2 = () => (
        <div className="w-full my-4">
            <div className="w-full mb-4">
                <img loading="lazy" src="/images/FindAgentTLPeaceOfMind.webp" alt="Yosemite National Park" className="w-full" />
            </div>
            When your travel plans throw you a curve-ball, don&apos;t let it take you off course. Travel Leaders business travel specialists work directly with airlines, hotels, and all other providers to ensure that your needs are met. Don&apos;t waste time fretting about a cancelled flight. When you work with us, you&apos;ll have peace of mind knowing that you will arrive promptly at your destination each and every time. Let us handle the stress of traveling so you can concentrate on what really matters.
        </div>
    );

    const text3 = () => (
        <div className="w-full my-4">
            <div className="w-full mb-4">
                <img loading="lazy" src="/images/FindAgentTLSpecialized.webp" alt="Two women looking at a computer screen" className="w-full" />
            </div>
            When searching for a travel &rdquo;expert&ldquo;, it can be hard to find someone who understands your industry&apos;s unique travel needs. With trained agents experienced in a variety of industry specializations such as legal, government, education and many more, Travel Leaders can provide the insider knowledge to ensure your business travels better for less.
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Business Travel - We are your Ally in Business Travel</title>
                <meta name="title" content="Find a Travel Expert | Travel Leaders Business" />
                <meta name="description" content="Unlock unparalleled expertise and seamless corporate travel solutions with our dedicated team of business travel experts, ensuring optimized itineraries and hassle-free journeys for your company&apos;s success." />
            </Helmet>
            <Hero
                title="Find a Travel Expert"
                upperText=""
                lowerText=""
                fileName="/images/hero-findATravelExpert.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20">
                        <h2 className="block uppercase text-lg">Find a Travel Management Company</h2>
                        <div className="mt-2">
                            With local travel management companies stationed across the United states, Travel Leaders has the business travel expertise you deserve.
                            We understand the demands placed on the modern business traveler, so we are available around the clock to ensure that you are never left in the dark.
                        </div>
                        <div className="mt-2">Let Travel Leaders handle your travel so you can focus on your business.</div>
                    </div>
                </div>

                <div className="my-4">
                    <div className="mt-4 mx-4 md:mx-20 flex flex-col md:flex-row">
                        <div className="w-full md:w-2/3">
                            <div className="w-32 border-b-2 border-red-700 mb-2 my-4 mx-0" />
                            <div className="text-lg font-bold mb-4">Search By Industry</div>
                            <div className="grid grid-cols-2 gap-2">
                                {getIndustries().map((x) => (
                                    <div key={x.itemId}>
                                        <Link
                                            className="cursor hover:text-red-700 hover:underline"
                                            to={`/agencies?slctIndustry=${x.itemId}`}
                                        >
                                            {x.itemName}
                                        </Link>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className="w-full md:w-1/3">
                            <div className="border border-gray-800 p-4">
                                <div className="w-32 border-b-2 border-red-700 mb-2 my-4 mx-0" />
                                <div className="text-lg font-bold mb-4">Find a Travel Management Company Near You</div>
                                <div>
                                    <SelectInput
                                        options={[{ label: 'Select a State', value: '' }].concat(getStates().map((x) => ({
                                            value: x.state,
                                            label: getStateName(x.state),
                                        })))}
                                        placeholder="Select a State"
                                        onSelected={(val) => setSelectedState(val)}
                                        value={selectedState}
                                    />
                                </div>
                                <div className="mt-2">
                                    <Button
                                        title="Search"
                                        padding="py-2"
                                        onClick={() => onSearch()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <div className="mx-4 md:mx-20 bg-blue-50 p-4">
                        <section className="justify-center hidden-tw md:flex">
                            <div className="flex flex-row w-full justify-between mx-4  text-lg uppercase gap-4 py-4 relative">
                                <div className="cursor-pointer" onClick={() => setSelected(1)}>
                                    <div
                                        className={selected === 1 ? 'underline relative font-semibold' : ''}
                                        style={selected === 1 ? { textDecorationColor: '#B91C1C', textUnderlineOffset: '10px' } : {}}
                                    >
                                        The Travel Leaders Difference
                                    </div>
                                </div>
                                <div className="cursor-pointer" onClick={() => setSelected(2)}>
                                    <div
                                        className={selected === 2 ? 'underline relative font-semibold ' : ''}
                                        style={selected === 2 ? { textDecorationColor: '#B91C1C', textUnderlineOffset: '10px' } : {}}
                                    >
                                        Peace Of Mind
                                    </div>
                                </div>
                                <div className="cursor-pointer" onClick={() => setSelected(3)}>
                                    <div
                                        className={selected === 3 ? 'underline relative font-semibold' : ''}
                                        style={selected === 3 ? { textDecorationColor: '#B91C1C', textUnderlineOffset: '10px' } : {}}
                                    >
                                        Specialized Attention
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="justify-center md:mb-20 bg-footer-slate relative md:hidden px-6 py-4">
                            <Accordion allowZeroExpanded preExpanded={['a']} className="Why">
                                <AccordionItem uuid="a" className="border-0">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={`uppercase rounded shadow-xl ${barColor} py-4 max-w-7xl ${barTextColor} text-center my-4`}>
                                            The Travel Leaders Difference
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="rounded bg-white px-2">
                                        {text1()}
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="b" className="border-0">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={`uppercase rounded shadow-xl ${barColor} py-4 max-w-7xl ${barTextColor} text-center my-4`}>
                                            Peace Of Mind
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="rounded bg-white px-2">
                                        {text2()}
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem uuid="c" className="border-0">
                                    <AccordionItemHeading>
                                        <AccordionItemButton className={`uppercase rounded shadow-xl ${barColor} py-4 max-w-7xl ${barTextColor} text-center my-4`}>
                                            Specialized Attention
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="rounded bg-white px-2">
                                        {text3()}
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>
                        </section>

                        <div className="text-lg justify-center md:flex mx-4 hidden-tw md:block">
                            {selected === 1 && (
                                <div>
                                    {text1()}
                                </div>
                            )}
                            {selected === 2 && (
                                <div>
                                    {text2()}
                                </div>
                            )}
                            {selected === 3 && (
                                <div>
                                    {' '}
                                    {text3()}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FindATravelExpert;
