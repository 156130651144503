import React from 'react';
import PropTypes from 'prop-types';
import {
    PiPhoneCall,
    PiEnvelopeSimple,
    PiLink,
    PiHeadset,
    PiBellRinging,
    PiAirplaneTilt,
    PiSuitcaseRolling,
    PiGlobeStand,
    PiCurrencyCircleDollar,
} from 'react-icons/pi';

function Button({
    onClick,
    title,
    disabled,
    width,
    padding,
    fontSize,
    inverted,
    icon,
}) {
    function renderSwitch(param) {
        switch (param) {
            case 'email':
                return <PiEnvelopeSimple />;
            case 'phone':
                return <PiPhoneCall />;
            case 'link':
                return <PiLink />;
            case 'headset':
                return <PiHeadset />;
            case 'bell':
                return <PiBellRinging />;
            case 'plane':
                return <PiAirplaneTilt />;
            case 'suitcaseRolling':
                return <PiSuitcaseRolling />;
            case 'globe':
                return <PiGlobeStand />;
            case 'dollar':
                return <PiCurrencyCircleDollar />;
            default:
                return null;
        }
    }
    return (
        <button
            type="button"
            className={[`flex space-x-5 items-center justify-evenly px-3 rounded  transition duration-500 ease-in-out border border-gray-800 ${padding} ${fontSize} ${width}`,
                inverted ? 'text-gray-800 hover:bg-gray-800 hover:text-white bg-blue-50' : 'text-white bg-gray-800 hover:bg-white hover:text-gray-800',
                disabled ? 'text-gray-300 bg-gray-500' : 'text-white'].join(' ')}
            onClick={onClick}
        >
            {icon ? (
                <span className="text-2xl">
                    {renderSwitch(icon)}
                </span>
            ) : ''}
            <span className="font-bold">{title}</span>
        </button>
    );
}

Button.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
    inverted: PropTypes.bool,
    icon: PropTypes.string,

};

Button.defaultProps = {
    disabled: false,
    width: 'w-full',
    padding: 'py-3 px-6',
    fontSize: 'text-sm',
    inverted: false,
    icon: '',
};

export default Button;
