/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Home from './pages/Home';
import FindATravelExpert from './pages/FindATravelExpert';
import Agencies from './pages/Agencies';
import Agency from './pages/Agency';
import Business from './pages/Business';
import GlobalServices from './pages/GlobalServices';
import ProgramManagement from './pages/ProgramManagement';
import RiskMgmt from './pages/RiskMgmt';
import TravelerExperience from './pages/TravelerExperience';
import Technology from './pages/Technology';
import DutyOfCare from './pages/DutyOfCare';
import OnlineBookingTools from './pages/OnlineBookingTools';
import Reporting from './pages/Reporting';
import UnusedTicketTracking from './pages/UnusedTicketTracking';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import About from './pages/About';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import { AppContextProvider } from './contexts/AppContext';
import Footer from './components/Footer';
import VirtualPayments from './pages/VirtualPayments';

function App() {
    let marginTop = '';

    useEffect(() => {
        if (window.location.search.includes('utm')) {
            const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
                const [k, v] = s.split('=');
                if (!k.includes('utm') && !k.includes('gclid')) {
                    return {};
                }
                return Object.assign(acc, { [k]: v });
            }, {});
            const cookies = new Cookies();
            cookies.set('google-utm', JSON.stringify(params),
                { expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)), path: '/' });
        }
    }, []);

    const queryParams = new URLSearchParams(window.location.search);
    const hasNav = queryParams.get('nav');
    const navCookie = new Cookies();

    if ((hasNav === '0') || (navCookie.get('hideNav'))) {
        marginTop = '';
    }

    return (
        <AppContextProvider>
            <Router forceRefresh>

                <div className="bg-white mx-auto">
                    <header>
                        <Header />
                    </header>
                    <main>
                        <div className={`w-full ${marginTop}`}>
                        <Switch>
                            <Route path="/" component={Home} exact />
                            <Route path="/find-a-travel-expert" component={FindATravelExpert} exact />
                            <Route path="/agencies" component={Agencies} exact />
                            <Route path="/agency/:id" component={Agency} exact />
                            <Route path="/business" component={Business} exact />
                            <Route path="/business/global-services" component={GlobalServices} exact />
                            <Route path="/business/program-management" component={ProgramManagement} exact />
                            <Route path="/business/risk-management" component={RiskMgmt} exact />
                            <Route path="/business/traveler-experience" component={TravelerExperience} exact />
                            <Route path="/technology" component={Technology} exact />
                            <Route path="/technology/duty-of-care" component={DutyOfCare} exact />
                            <Route path="/technology/online-booking-tools" component={OnlineBookingTools} exact />
                            <Route path="/technology/reporting" component={Reporting} exact />
                            <Route path="/technology/unused-ticket-tracking" component={UnusedTicketTracking} exact />
                            <Route path="/technology/virtual-payments" component={VirtualPayments} exact />
                            <Route path="/customers" component={Customers} exact />
                            <Route path="/customers/:id" component={Customer} exact />
                            <Route path="/thankYou" component={ThankYou} exact />
                            <Route path="/about" component={About} exact />
                            <Route path="*" component={NotFound} />
                        </Switch>
                        </div>
                    </main>
                    <footer>
                        <Footer />
                    </footer>

                </div>
            </Router>
        </AppContextProvider>
    );
}

export default App;
