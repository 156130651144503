/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';
import Button from '../components/elements/Button';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function TravelerExperience() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Business Travel',
            url: '/business',
        },
        {
            label: 'Traveler Experience',
        },
        ]);
    }, []);

    const titleBar = (title, image) => (
        <div
            className="flex flex-row mx-2 my-8 md:mx-20 p-4 text-white text-center text-3xl uppercase justify-center bg-gray-800"
            style={{ background: `url('/images/${image}')`, backgroundSize: 'cover' }}
        >
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    const backToTop = <div className="mx-4 md:mx-20 text-right -mt-8 "><span className="text-gray-800 hover: hover:text-red-700 cursor-pointer" onClick={() => goToAnchor('topics')}>Back to Topics</span></div>;
    return (
        <>
            <Helmet>
                <title>Traveler Experience - Travel Leaders Business</title>
                <meta name="title" content="Traveler Experience - Travel Leaders Business" />
                <meta name="description" content="Enhance your business traveler experience with our comprehensive suite of services, featuring convenient after-hours assistance, seamless mobile app integration, expedited passport services, and an array of VIP amenities tailored to elevate your journey." />
            </Helmet>
            <Hero
                title="Traveler Experience"
                upperText=""
                lowerText=""
                fileName="/images/hero-traveler-experience1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4" id="topics">
                    <div className="mx-2 my-8 md:mx-20 relative text-center bg-white">
                        <div
                            className="flex  flex-col flex-nowrap relative w-full p2 md:p-8  bg-gray-800 text-white"
                            style={{ background: 'url(\'/images/bg-business.webp\')', backgroundSize: 'cover' }}
                        >
                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                            <div className="py-2 px-4 md:px-0 md:py-0 w-full text-left">
                                <h3 className="block font-semibold uppercase py-2 tracking-wide">
                                    Click on the topics below to learn more
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
                                    <div className="w-full">
                                        <Button
                                            title="After Hours Support"
                                            onClick={() => goToAnchor('after')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Mobile Apps"
                                            onClick={() => goToAnchor('mobile')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Online Booking Tools"
                                            onClick={() => goToAnchor('online')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Passport &amp; Visa Services"
                                            onClick={() => goToAnchor('passport')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Traveler Wellbeing"
                                            onClick={() => goToAnchor('traveler')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="VIP"
                                            onClick={() => goToAnchor('vip')}
                                            inverted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4" id="after">
                    {titleBar('After Hours Support', 'TitleAfterhours.webp')}
                    <InfoImageBar
                        text={`Travel Leaders 24 was born out of the need to provide a superior after-hours experience with a high level of traveler support.
                               We know that travelers experiencing a delay or cancellation need to reach someone who can help right away.
                               We are committed to providing your travelers and travel arrangers with 24/7 agency support.`}
                        imageUrl="images/afterhours.webp"
                        imageAlt="Business Person walking through a city at dusk"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <div className="my-4" id="mobile">
                    {titleBar('Mobile Apps', 'TitleMobileApps.webp')}
                    <InfoImageBar
                        text={`Equip your travelers with everything they need for their upcoming journey.
                               From crucial details provided by their travel advisor to helpful services ensuring a seamless and enjoyable experience, the app has it all.
                               It's an easy, convenient and paperless way to familiarize clients with their unique itinerary as well as their destination.
                               It even offers an augmented reality feature to discover nearby hotspots.
                               <ul class="list-outside ml-6" style="list-style-type:circle">
                                <li class="py-1">Itineraries for past and upcoming trips</li>
                                <li class="py-1">Detailed flight information, check-in and trip management</li>
                                <li class="py-1">Real-time alerts about gate changes, delays or cancellations</li>
                                <li class="py-1">Share itineraries via text, e-mail or social media</li>
                                <li class="py-1">Notifications about newsworthy events</li>
                                <li class="py-1">Detailed travel guides, currency converter, maps and more</li>
                              </ul>`}
                        imageUrl="images/mobileapp.webp"
                        imageAlt="person holding multiple mobile devices"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <div className="my-4" id="online">
                    {titleBar('Online Booking Tools', 'TitleOnlineBooking.webp')}
                    <InfoImageBar
                        text="Travel Leaders offers our business clients special negotiated rates for leading online booking tools, Concur and Deem. Our integration with these technologies enables our clients to secure business travel at any time of the day or night."
                        imageUrl="images/onlinebooking.webp"
                        imageAlt="Person sitting at a laptop looking for flights"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <div className="my-4" id="passport">
                    {titleBar('Passport &amp; Visa Services', 'TitlePassportVisa.webp')}
                    <InfoImageBar
                        text={`Travel Leaders can provide assistance with passports and visas through our partnership with CIBTvisas, the leading global provider of travel documents.
                               According to the Wall Street Journal, "CIBTvisas is the leader in the travel visa and passport industry."
                               <span class="block font-semibold uppercase py-2 tracking-wide block mt-4">About CIBTvisas:</span>
                               <ul class="list-outside ml-6" style="list-style-type:circle;">
                                <li class="py-1">Thorough review of completed traveler applications by highly trained experts.</li>
                                <li class="py-1">Handle over 1.2 million visas and passport requests annually.</li>
                                <li class="py-1">Operate 70 wholly owned offices in 25 countries providing services worldwide.</li>
                               </ul>`}
                        imageUrl="images/passport.webp"
                        imageAlt="Someone showing their US passport"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <div className="my-4" id="traveler">
                    {titleBar('Traveler Wellbeing', 'TitleWellbeing.webp')}
                    <InfoImageBar
                        text={`Travel Leaders works with you to incorporate traveler well-being into your corporate travel policy.
                               When companies prioritize the well-being of their staff, it can boost job satisfaction and give employees peace of mind when they are far away from home.
                               Travelers know that when they are away on business, there is someone they can call 24/7 if they have a problem — whether it's rebooking a delayed or cancelled flight, switching hotels or extending their stay.`}
                        imageUrl="images/wellbeing.webp"
                        imageAlt="An employee smiling while people in the background are working"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <div className="my-4" id="vip">
                    {titleBar('VIP', 'TitleVip.webp')}
                    <InfoImageBar
                        text={`<h3 class="block font-semibold py-2 tracking-wide">
                                The top performers in your office deserve special consideration.
                               </h3>
                               While they're out on the road generating new business, we can offer exceptional benefits in the highest level of customer care.
                               As your business expands, bring on an experienced partner who can provide a consistently outstanding travel experience.
                               Travel Leaders offers access to our global concierge services at no additional cost.
                               Our highly trained professionals know how to provide high-touch service with concierge-like, consultative approach.
                               They instinctively understand and anticipate executive travelers' needs from the time they start the booking process until the end of their journey.
                               <div class="mt-4">We provide executives with the highest level of priority and care, ensuring no detail is overlooked.
                               Service is tailored to the individual traveler to ensure the most convenient, creative and thoughtful planning to meet and exceed the most demanding needs of VIP travelers.
                               Our agents work as an extension of your executive administrative team with personalized travel planning and assistance.</div>`}
                        imageUrl="images/vip.webp"
                        imageAlt="person sitting in the back of a livery car"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}

                <FeaturedAgency />
            </div>
        </>
    );
}

export default TravelerExperience;
