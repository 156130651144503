import React from 'react';
import PropTypes from 'prop-types';

function Hero({
    title,
    fileName,
    height,
    fileType,
    upperText,
    lowerText,
    backgroundPosition,
}) {
    return (
        <div className="text-4xl flex flex-col justify-center text-center" style={{ height: '100%', maxHeight: height }}>
            <div className="relative flex flex-col items-center justify-center w-full min-h-full max-w-screen-4xl mx-auto bg-cover bg-center bg-no-repeat">
                {fileType === 'video' ? (
                    <div className="overflow-hidden" style={{ width: '100%', position: 'relative' }}>
                        <video
                            className="w-full"
                            style={{
                            margin: 'auto',
                            display: 'block',
                            }}
                            src={fileName}
                            autoPlay
                            loop
                            muted
                        />
                        <div className="absolute text-left ml-4 md:ml-6 lg:ml-12 xl:ml-14 2xl:ml-24 top-1 md:top-8 lg:top-1/4 uppercase">
                            <h1>
                                <div className="text-gray-800 text-lg md:text-3xl font-semibold underline text-shadow-xl-inverse" style={{ textDecorationColor: '#EE3123', textUnderlineOffset: '10px' }}>
                                    {upperText}
                                </div>
                                <div className="text-white mt-4 md:mt-6 mb-1 md:mb-2 text-xl md:text-5xl font-semibold text-shadow-xl">
                                    {title}
                                </div>
                                <div className="text-white text-xl md:text-5xl md:text-3xl font-semibold text-shadow-xl">
                                    {lowerText}
                                </div>
                            </h1>
                        </div>
                    </div>
                ) : ''}
                {fileType === 'image' ? (
                    <div
                        className="w-full relative flex flex-col items-center justify-center min-h-full bg-cover bg-center bg-no-repeat"
                        style={{ height, backgroundPosition, backgroundImage: `url('${fileName}')` }}
                    >
                        <div className="absolute inset-0 w-full h-full bg-black bg-opacity-10" />
                        <div className="container relative flex flex-col px-4 md:px-40">
                            <h1 className="relative z-20 text-2xl md:text-5xl font-bold leading-tight text-gray-800 uppercase mt-20 underline" style={{ textDecorationColor: '#EE3123', textUnderlineOffset: '10px' }}>
                                {title}
                            </h1>
                        </div>
                    </div>
                ) : ''}
            </div>
        </div>
    );
}

Hero.propTypes = {
    title: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    height: PropTypes.string,
    fileType: PropTypes.string,
    upperText: PropTypes.string,
    lowerText: PropTypes.string,
    backgroundPosition: PropTypes.string,
};

Hero.defaultProps = {
    title: '',
    height: '500px',
    fileType: 'image',
    upperText: '',
    lowerText: '',
    backgroundPosition: '',
};

export default Hero;
