/* eslint-disable prefer-template*/
/* eslint-disable prefer-destructuring */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PiXCircle } from 'react-icons/pi';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import Loading from './elements/Loading';
import useAppStore from '../contexts/useAppStore';
import API from '../services/API';
import Button from './elements/Button';
import {
    callMe,
    CONTACT_METHOD_EMAIL,
    CONTACT_METHOD_PHONE,
    modalCustomStyles,
} from '../utils';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import 'react-toastify/dist/ReactToastify.css';

function FeaturedAgency({
    searchTitle,
    industryId,
    technologyId,
}) {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const myIp = query.get('ip') || null;
    const [agentData, setAgentData] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedAgency, setSelectedAgency] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
    } = useAppStore();

    const userLocationCookie = new Cookies();

    const getUserLocation = async () => {
        setLoading(true);
        API.getIpLocation({
            myIp,
        }).then((ipResult) => {
            if (ipResult.responseStatus === 1) {
                /*SET THE COOKIE*/
                userLocationCookie.set('userLocation', JSON.stringify(ipResult.data),
                    {
                        expires: new Date(Date.now() + (60 * 60 * 24 * 1000 * 7)),
                        path: '/',
                        sameSite: 'lax',
                    });
                /*GET CLOSEST AGENTS*/
                API.fetchAgencies({
                    agencyState: '',
                    industry: industryId,
                    technology: technologyId,
                    agencyId: 0,
                    language: '',
                    latitude: (industryId || technologyId) ? null : ipResult.data.latitude,
                    longitude: (industryId || technologyId) ? null : ipResult.data.longitude,
                    radius: 100,
                    rows: 1,
                }).then(async (agentResult) => {
                    if (agentResult.responseStatus === 1) {
                        setAgentData(agentResult.data);
                    } else {
                        toast(agentResult.error.message);
                    }
                });
            } else {
                toast(ipResult.error.message);
            }
            setLoading(false);
        });
    };

    const getMatchedAgents = async () => {
        setLoading(true);
        API.fetchAgencies({
            agencyState: '',
            industry: industryId,
            technology: technologyId,
            agencyId: 0,
            language: '',
            latitude: (industryId || technologyId) ? null : userLocationCookie.get('userLocation').latitude,
            longitude: (industryId || technologyId) ? null : userLocationCookie.get('userLocation').longitude,
            radius: (industryId || technologyId) ? null : 100,
            rows: 1,
        }).then(async (result) => {
            if (result.responseStatus === 1) {
                setAgentData(result.data);
            } else {
                toast(result.error.message);
            }
            setLoading(false);
        });
    };

    /*if cookie is not set, run userlocation and find agents*/
    if ((!userLocationCookie.get('userLocation')) || (myIp !== null)) {
        useEffect(() => {
            getUserLocation();
        }, []);
    }

    /*if cookie is set, use preset cookie data*/
    if ((userLocationCookie.get('userLocation')) && myIp === null) {
        useEffect(() => {
            getMatchedAgents();
        }, []);
    }

    const goToAgencyBio = (agency, section = '') => {
        let searchTerms = '';
        if (window.location.search.substr(1).length > 0) {
            searchTerms = '&' + window.location.search.substr(1);
        }
        history.push(`/agency/${agency.agencyId}?ref=search${searchTerms}`);
    };

    const emailMe = (agency) => {
        setSelectedAgency(agency);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };

    return (
        <div className="w-full py-14 px-4 bg-light-blue">
            <ToastContainer />
            <div className="mx-4 md:mx-20 text-center">
                <h2 className="tl-cyan uppercase text-xl font-bold tracking-wide">
                    {`Learn more about this featured business travel agency ${searchTitle}`}
                </h2>
                <p className="mx-4 text-lg leading-7 tl-black-900">
                    For more details, connect with this friendly, knowledgeable travel agency today.
                </p>
            </div>
            <div className="flex mx-4 md:mx-20 p-4  bg-blue-50 text-left">
                {agentData && (
                    <>
                        {agentData?.agency.map((agency, index) => (
                            <div
                                key={agency.agencyId}
                                className="flex gap-2 w-full justify-between items-top flex-col md:flex-row"
                            >
                                <div className="w-full md:w-2/4 pb-6 md:pb-0">
                                    <div className="w-full text-lg font-semibold uppercase text-gray-800 text-left md:pb-4">
                                        {agency.agencyName}
                                    </div>
                                    <div className="w-full font-medium text-gray-800 md:pb-4">
                                        {agency.profileTitle}
                                    </div>
                                    <div className="w-full italic md:pb-4">
                                        {agency.city}
                                        {', '}
                                        {agency.state}
                                    </div>
                                    <div className="grid grid-cols-2 grid-rows-2 gap-4 md:w-3/4 pt-4 md:pt-0">
                                        <div className="col-span-1">
                                            <Button
                                                title="Call Us"
                                                icon="phone"
                                                padding="py-2"
                                                onClick={() => callMe(agency, () => {
                                                    setSelectedAgency(agency);
                                                    setIsPhoneFormModalOpen(true);
                                                })}
                                                inverted
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <Button
                                                title="Email Us"
                                                icon="email"
                                                padding="py-2"
                                                onClick={() => emailMe(agency)}
                                                inverted
                                            />
                                        </div>
                                        <div className="col-span-2 row-start-2">
                                            <Button
                                                title="Learn More"
                                                padding="py-4 font-semibold"
                                                onClick={() => goToAgencyBio(agency)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/4 text-center md:text-left">
                                    {agency.industry && (
                                        <div>
                                            <span className="w-full text lg font-semibold text-gray-800 md:pb-4">Our Specialties:</span>
                                            {agency.industry && agency.industry.map((x) => (
                                                <span className="py-1 block" key={x.indId}>
                                                    <span className="">{x.industryName}</span>
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="w-full md:w-1/4 self-center order-first pb-6 md:pb-0 md:order-last">
                                    <img
                                        loading="lazy"
                                        className="mx-auto rounded-xl w-48 h-48 object-cover overflow-hidden hover:scale-105 transform ease-in-out duration-1000 cursor-pointer border hover:border-red-700"
                                        src={agency.profileImage}
                                        alt={agency.agencyName}
                                        onClick={() => goToAgencyBio(agency)}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <PiXCircle className="text-red-500 text-right text-xl" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agency={selectedAgency} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agency={selectedAgency}
                            phone={selectedAgency.phone}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </div>
    );
}

FeaturedAgency.propTypes = {
    industryId: PropTypes.string,
    technologyId: PropTypes.string,
    searchTitle: PropTypes.string,
};

FeaturedAgency.defaultProps = {
    industryId: '',
    technologyId: '',
    searchTitle: 'near you',
};

export default FeaturedAgency;
