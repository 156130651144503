/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function VirtualPayments() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
            url: '/technology',
        },
        {
            label: 'Virtual Payments',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    return (
        <>
            <Helmet>
                <title>Virtual Payments - Travel Leaders Business</title>
                <meta name="title" content="Virtual Payments - Travel Leaders Business" />
                <meta name="description" content="Safeguard your business from payment fraud with our secure virtual payment solutions, providing peace of mind and unparalleled protection for every transaction in your corporate travel arrangements." />
            </Helmet>
            <Hero
                title="Virtual Payments"
                upperText=""
                lowerText=""
                fileName="/images/hero-virtual-payments1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`Make the most of this cutting-edge technology to securely transmit virtual payments to hotels and protect your company from credit card fraud.
                               Travel Leaders SecurePay, powered by Conferma, is one of the world's foremost providers of virtual payment technologies, offering a complete set of solutions in a single platform.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default VirtualPayments;
