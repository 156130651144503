/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

function SelectInput({
    options, onSelected, placeholder, value,
}) {
    return (
        <Dropdown
            value={value}
            options={options}
            onChange={(e) => onSelected(e.value)}
            placeholder={placeholder}
            controlClassName="rounded"
            className="w-full"
            menuClassName="text-left"
            arrowClassName="red-arrow"
            placeholderClassName="text-xs tracking-wide tl-black-900"
        />
    );
}
SelectInput.defaultProps = {

    placeholder: 'Select an option',
    value: '',
};
SelectInput.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSelected: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
};
export default SelectInput;
