/* eslint-disable  import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import MobileMenuItems from './MobileMenuItems';

const MobileDropdown = ({ submenus, dropdown }) => (
        <ul className={`dropdown ${dropdown ? 'show' : ''}`}>
            {submenus.map((submenu, index) => (
                <MobileMenuItems items={submenu} key={index} />
            ))}
        </ul>
    );

export default MobileDropdown;
