/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiKey,
    PiShieldCheck,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function Reporting() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
            url: '/technology',
        },
        {
            label: 'Reporting',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    return (
        <>
            <Helmet>
                <title>Reporting - Travel Leaders Business</title>
                <meta name="title" content="Reporting - Travel Leaders Business" />
                <meta name="description" content="Gain valuable insights and optimize your business travel strategies with our comprehensive reporting tools, designed to enhance efficiency and drive informed decision-making every step of the way." />
            </Helmet>
            <Hero
                title="Reporting"
                upperText=""
                lowerText=""
                fileName="/images/hero-reporting1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text="Travel Leaders uses Business Travel Intelligence and other reporting tools to organize travel data into the following categories, which then becomes useful data extracts:"
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20 p-4 md:p-8 bg-blue-50">
                        <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                        <div className="flex flex-row flex-nowrap items-start">
                            <div className="px-2 text-4xl text-red-700 flex justify-center">
                                <PiKey />
                            </div>
                            <div className="w-full">
                                <span className="block font-semibold uppercase py-2 tracking-wide">
                                    Spend Analysis
                                </span>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <ul
                                    className="list-outside ml-6"
                                    style={{ listStyleType: 'circle' }}
                                >
                                    <li className="py-2">Reconciliation analysis</li>
                                    <li className="py-2">Crisis management</li>
                                    <li className="py-2">Supplier management</li>
                                </ul>
                            </div>
                            <div>
                                <ul
                                    className="list-outside ml-6"
                                    style={{ listStyleType: 'circle' }}
                                >
                                    <li className="py-2">Policy/Risk management</li>
                                    <li className="py-2">Activity management</li>
                                    <li className="py-2">Spend management</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <InfoBar
                        icon="shieldCheck"
                        iconPosition="left"
                        text={`Key travel program stakeholders can be granted full or partial access to the reporting database.
                               Customized reporting is easily managed through a menu-driven report-building application.
                               Standard and customized report templates can be created and shared to your company's key contributors.
                               Any individual or set of reports can be set to produce and distribute via email on an automated delivery schedule with simple programming commands.
                               Travel Leaders provides a complete demonstration of reporting capabilities at kickoff and on an ongoing basis. `}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <FeaturedAgency
                    technologyId="28"
                />
            </div>
        </>
    );
}

export default Reporting;
