/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SlideDown from 'react-slidedown';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import Button from '../components/elements/Button';
import BreadCrumbs from '../components/elements/BreadCrumbs';
import 'react-slidedown/lib/slidedown.css';


function About() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };

    const stringToBoolean = (str) => (
        str === 'true'
    );

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [showInsight, setShowInsight] = useState(stringToBoolean(query.get('showInsights')) || false);

    const [showFull, setShowFull] = useState(showInsight);


    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'About Us',
        },
        ]);
    }, []);

    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    const insightsCard = (title, image, url) => (
        <div className="w-full h-full relative bg-gray-800 overflow-hidden text-white hover:text-red-500 transition-colors duration-500">
            <a href={url}>
                <img
                    src={`/images/${image}`}
                    alt={title}
                    className="h-full w-full object-cover hover:opacity-50 transition-all duration-500 scale-105 hover:scale-110 transform ease-in-out duration-1000"
                    loading="lazy"
                />
                <div className="p-4 uppercase text-lg font-medium absolute bottom-0 w-full bg-gray-800 bg-opacity-70">
                    {title}
                </div>
            </a>
        </div>
    );
    return (
        <>
            <Helmet>
                <title>About Us | Travel Leaders Business</title>
                <meta name="title" content="About Us | Travel Leaders Business" />
                <meta name="description" content="Discover how Travel Leaders Business combines industry expertise with personalized service to streamline your business travel needs efficiently and cost-effectively." />
            </Helmet>
            <Hero
                title="About Us"
                upperText=""
                lowerText=""
                fileName="/images/hero-about2.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        title="Are you looking for a travel program that's run by business people just like you? "
                        text={`We at Travel Leaders understand the complexity of running a business.
                               We know how important it is to focus on bottom-line results.
                               When you partner with us, you're working with the decision-makers, giving you a program that is more nimble, flexible and accessible than any of our competition.
                               <div class="mt-4">Each of our certified business travel specialists completes 150 hours of intense coursework, so you can have confidence that you are working with the industry's best and brightest.
                               When you combine our business travel expertise with your specific insights, all the requirements of successful program can be realized.</div>
                               <div class="mt-4"> With local leadership and dedicated business travel specialists, each travel management office across the country is driven to meet your company&apos;s unique travel needs.
                               Combine local leadership with expertise and resources of Travel Leaders Group, a <span class="font-bold">$20 billion per year enterprise</span>, and the result is truly a winning formula.
                               <div class="mt-4 hidden"><a href="" class="text-lg text-red-700 underline hover:text-gray-800">Learn More</a>`}
                        icon=""
                        iconPosition="top"
                    />
                </div>
                <div className="my-4 hidden">
                    <div className="mx-4 md:mx-20">
                        <img loading="lazy" src="/images/FeaturedInsights.webp" alt="Lightbulb on a chalkboard" className="w-full" />
                        <div className="p2 md:p-8 bg-blue-50 bg-opacity-90 flex flex-col md:flex-row flex-wrap">
                            <div className="w-full md:w-3/4">
                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                <div className="py-2 mr-0 md:mr-4">We&apos;ve built a collection of insightful resources to both inspire and empower you to make smart decisions that will impact the future of your travel program.</div>
                            </div>
                            <div className="w-full md:w-1/4">
                                {!showFull ? (
                                    <Button
                                        title="Show Featured Insights"
                                        padding="py-2"
                                        onClick={() => setShowFull(!showFull)}
                                    />
                                ) : (
                                        <Button
                                            title="Hide Featured Insights"
                                            padding="py-2"
                                            onClick={() => setShowFull(!showFull)}
                                        />
                                    )}
                            </div>
                            <div className="block w-full" id="insights">
                                <SlideDown className="my-dropdown-slidedown">
                                    {showFull ? (
                                        <div className="my-4">
                                            <div className="mx-2 my-8 md:mx-20 grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center items-center">
                                                {insightsCard('Marketing', 'insights_marketing.webp', 'https://google.com')}
                                                {insightsCard('Booking Tools', 'insights_booking-tool.webp', 'https://google.com')}
                                                {insightsCard('Lead Generation', 'insights_leadgen.webp', 'https://google.com')}
                                                {insightsCard('Luxury Travel Solutions', 'insights_luxury.webp', 'https://google.com')}
                                                {insightsCard('Programs', 'insights_programs.webp', 'https://google.com')}
                                                {insightsCard('Corporate Travel', 'insights_corporate.webp', 'https://google.com')}
                                                {insightsCard('Events & Education', 'insights_events.webp', 'https://google.com')}
                                                {insightsCard('Partnerships', 'insights_partnerships.webp', 'https://google.com')}
                                            </div>
                                        </div>
                                    ) : ''}
                                </SlideDown>
                            </div>
                        </div>
                    </div>
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default About;
