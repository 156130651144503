/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template*/
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAppStore from '../contexts/useAppStore';
import Button from './elements/Button';
import SelectInput from './elements/SelectInput';
import { getStateName } from '../utils';

function SearchBox() {
    const {
        getStates,
        getIndustries,
    } = useAppStore();

    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const history = useHistory();

    const label = (title) => <div className="uppercase font-bold text-sm mb-2 hidden sm:block">{title}</div>;

    const onSearch = () => {
        const filters = [];

        if (selectedState !== '') {
            filters.push(`slctState=${selectedState}`);
        }
        if (selectedIndustry !== '') {
            filters.push(`slctIndustry=${encodeURIComponent(selectedIndustry)}`);
        }

        history.push(`/agencies?${filters.join('&')}`);
    };

    return (
        <div className="bg-white md:shadow-xl px-8 py-6 z-40 text-center">
            <div className="mb-6 text-lg">
                Search for an expert travel management company in one or more of the following ways:
            </div>
            <div className="flex flex-col md:flex-row gap-4 sm:gap-8 justify-between">

                <div className="flex flex-col justify-center flex-1">
                    {label('')}
                    <div>
                        <SelectInput
                            options={[{ label: 'Select a State', value: '' }].concat(getStates().map((x) => ({
                                value: x.state,
                                label: getStateName(x.state),
                            })))}
                            placeholder="Select a State"
                            onSelected={(val) => setSelectedState(val)}
                            value={selectedState}
                        />
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div>
                        {label('')}
                        <div>
                            <SelectInput
                                options={[{ label: 'Select an Industry', value: '' }].concat(getIndustries().map((x) => ({
                                    value: x.itemId + '',
                                    label: x.itemName,
                                })))}
                                placeholder="Select an Industry"
                                onSelected={(val) => setSelectedIndustry(val)}
                                value={selectedIndustry}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center flex-1">
                    <div className="" />
                    <div className="mt-2">
                        <Button
                            title="Find A Travel Expert"
                            padding="py-2"
                            onClick={() => onSearch()}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SearchBox;
