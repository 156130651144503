import React from 'react';
import PropTypes from 'prop-types';
import {
    PiCheckSquareOffsetBold,
    PiSealCheckLight,
    PiWrench,
    PiLeaf,
    PiShieldCheck,
    PiTarget,
    PiClockClockwise,
    PiDeviceMobileCamera,
    PiChalkboard,
    PiChecks,
    PiAirplane,
} from 'react-icons/pi';

function InfoBar({
    title,
    text,
    icon,
    iconPosition,
    customCSS,
}) {
    return (
        <div className={`${customCSS} bg-blue-50 p-4 md:p-8`}>
            {iconPosition === 'top' ? (
                icon === 'seal' ? (
                    <div className="text-4xl text-red-700 p-4 md:p-0 md:pb-4">
                        <PiSealCheckLight />
                    </div>
                ) : icon === 'leaf' ? (
                    <div className="text-4xl text-red-700 p-4 md:p-0 md:pb-4">
                        <PiLeaf />
                    </div>
                    ) : icon === 'target' ? (
                        <div className="text-4xl text-red-700 p-4 md:p-0 md:pb-4">
                            <PiTarget />
                        </div>
                    ) : <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0 md:-mt-2" />
            ) : ''}
            <div className="flex flex-row flex-nowrap items-center">
                <div className={`${iconPosition === 'left' ? 'pr-8' : ''} text-4xl text-red-700 flex justify-center`}>
                    {iconPosition === 'left' ? (
                        <>
                            {icon === 'check' ? (<PiCheckSquareOffsetBold />) : ''}
                            {icon === 'wrench' ? (<PiWrench />) : ''}
                            {icon === 'shieldCheck' ? (<PiShieldCheck />) : ''}
                            {icon === 'clockClockwise' ? (<PiClockClockwise />) : ''}
                            {icon === 'deviceMobileCamera' ? (<PiDeviceMobileCamera />) : ''}
                            {icon === 'chalkboard' ? (<PiChalkboard />) : ''}
                            {icon === 'seal' ? (<PiSealCheckLight />) : ''}
                            {icon === 'checks' ? (<PiChecks />) : ''}
                            {icon === 'plane' ? (<PiAirplane />) : ''}
                        </>
                    ) : ''}
                </div>
                <div className="w-full">
                    {title !== '' ? (
                        <h2 className="block font-semibold uppercase py-2 tracking-wide">
                            {title}
                        </h2>
                    ) : ''}
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    );
}

InfoBar.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    customCSS: PropTypes.string,
};

InfoBar.defaultProps = {
    title: '',
    text: 'This is a placeholder',
    icon: '',
    iconPosition: '',
    customCSS: 'mx-2 my-8 md:mx-20',
};

export default InfoBar;
