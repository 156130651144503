/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiBookOpen,
    PiUsersThree,
    PiTarget,
    PiMoney,
    PiRepeat,
    PiAirplaneTilt,
    PiCar,
    PiBuildings,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';
import Button from '../components/elements/Button';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function ProgramManagement() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
            {
                label: 'Business Travel',
                url: '/business',
            },
            {
                label: 'Program Management',
            },
        ]);
    }, []);

    const titleBar = (title, image) => (
        <div
            className="flex flex-row mx-2 my-8 md:mx-20 p-4 text-white text-center text-3xl uppercase justify-center bg-gray-800"
            style={{ background: `url('/images/${image}')`, backgroundSize: 'cover' }}
        >
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );

    const arrowBar = (icon, title, text) => (
        <div className="mx-2 mb-4 md:mb-8 md:mx-20">
            <div
                className="rounded border-4 border-gray-800 flex justify-center items-center bg-blue-50 absolute z-50  my-10 md:my-5"
                style={{ height: '100px', width: '100px' }}
            >
                <div className="text-6xl">
                    {icon}
                </div>
            </div>
            <div
                className="bg-blue-50 flex justify-start items-center relative z-0"
                style={{ clipPath: 'polygon(10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%)', minHeight: '150px' }}
            >
                <div className="ml-28 md:ml-36 p-2">
                    <div className="block text-l md:text-xl font-bold text-gray-800 pb-4 uppercase">
                        {title}
                    </div>
                    <div className="">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );

    const meetingGridItem = (icon, title, text, colspan) => (
        <div className={colspan}>
            <div>
                {icon}
            </div>
            <h3 className="block text-l md:text-l font-bold text-gray-800 pb-4 md:h-24">
                {title}
            </h3>
            <div>
                {text}
            </div>
        </div>
    );
    const supplierInfo = (icon, image, title, text) => (
        <div className="mx-4 mb-4 md:mx-20 md:mb-8 flex flex-col md:flex-row justify-center items-stretch bg-blue-50 p-4">
            <div
                className="w-full md:w-1/3 text-center flex justify-center items-center text-white text-8xl"
                style={{ background: `url('/images/${image}')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                {icon}
            </div>
            <div className="w-full md:w-2/3>">
                <div className="ml-0 mt-4 md:mt-0 md:ml-4">
                    <div className="text-red-700 font-bold uppercase">
                        {title}
                    </div>
                    <div className="w-full border-b-2 my-2" />
                    <div className="" dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    );

    const accountText = `We&apos;ve learned that no two clients are the same. Each company has <span class="font-semibold">unique policies, travel patterns, growth expectations, required services, existing programs</span> and plans for future growth. At Travel Leaders, we devote the same level of attention to all our clients, regardless of size or spend.
                        <div class="w-32 border-b-2 border-red-700 my-4"></div>
                         We&apos;re constantly tracking metrics like online booking tool adoption, trip complexity and industry market to ensure our customers are fully supported. Dedicated program management means that we are anticipating your needs before you realize them. <span class="font-semibold">We&apos;ve helped companies</span> expand their travel program for decades. Put our accrued wisdom to work for your organization. `;

    const programText = `Travel Leaders achieves the highest benchmarks of customer service with an “above and beyond” attitude. We will guarantee service levels at 100% of Key Performance Indicators (KPIs) that are integral to the Service Level Agreement (SLA). We quantitatively and qualitatively measure our management activities over travel spending, coordinating local resources and applying industry best practices. 
                        <div class="w-32 border-b-2 border-red-700 my-4"></div>
                        <span class="font-bold">We document the incremental benefits regularly to prove our ongoing value.</span> `;
    const trainingText = `The training phase of a corporate travel program implementation is a critical period. Travel Leaders provides initial and ongoing education presentations to travelers and travel arrangers in a variety of formats.
                        <ul
                            class="list-outside ml-6"
                            style="list-style-type:circle"
                        >
                            <li class="py-2">Meet-and-greet with travel arrangers, executive administrators and groups of frequent travelers.</li>
                            <li class="py-2">Orientation seminars in person and online, covering travel policy, expensive procedures, service standards and feedback mechanisms. </li>
                            <li class="py-2">Internet advisories, newsletters and other internal communications that outline travel policies, new procedures and preferred suppliers. </li>
                        </ul>`;
    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    const backToTop = <div className="mx-4 md:mx-20 text-right -mt-8 "><span className="text-gray-800 hover: hover:text-red-700 cursor-pointer" onClick={() => goToAnchor('topics')}>Back to Topics</span></div>;
    return (
        <>
            <Helmet>
                <title>Program Management - Travel Leaders Business</title>
                <meta name="title" content="Program Management - Travel Leaders Business" />
                <meta name="description" content="Optimize your travel program management with expert coordination and strategic planning, enhancing efficiency and meeting organizational goals for seamless travel experiences." />
            </Helmet>
            <Hero
                title="Program Management"
                upperText=""
                lowerText=""
                fileName="/images/hero-program-mgmt1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4" id="topics">
                    <div className="mx-2 my-8 md:mx-20 relative text-center bg-white">
                        <div
                            className="flex  flex-col flex-nowrap relative w-full p2 md:p-8  bg-gray-800 text-white"
                            style={{ background: 'url(\'/images/bg-business.webp\')', backgroundSize: 'cover' }}
                        >
                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                            <div className="py-2 px-4 md:px-0 md:py-0 w-full text-left">
                                <h3 className="block font-semibold uppercase py-2 tracking-wide">
                                    Click on the topics below to learn more
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
                                    <div className="w-full">
                                        <Button
                                            title="Account &amp; Program Management"
                                            onClick={() => goToAnchor('account')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Contract Optimization"
                                            onClick={() => goToAnchor('contract')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Implementation"
                                            onClick={() => goToAnchor('implementation')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Meetings &amp; Events"
                                            onClick={() => goToAnchor('meetings')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Program Success"
                                            onClick={() => goToAnchor('program')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Supplier Partnerships"
                                            onClick={() => goToAnchor('supplier')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Sustainability"
                                            onClick={() => goToAnchor('sustainability')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Training"
                                            onClick={() => goToAnchor('training')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Travel Policy"
                                            onClick={() => goToAnchor('travel')}
                                            inverted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-4" id="account">
                    {titleBar('Account &amp; Program Management', 'TitleAccountProgramMgmt.webp')}
                    <InfoImageBar
                        text={accountText}
                        imageUrl="images/AccountProgramMgmt1.webp"
                        imageAlt="Employees sitting at a conference table reviewing blueprints"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}
                <div className="my-4" id="contract">
                    {titleBar('Contract Optimization', 'TitleContractOptimization.webp')}
                    <div className="mx-2 my-8 md:mx-20 h-full flex justify-center items-stretch flex-col md:flex-row">
                        <div className="w-full md:w-1/3 flex-none h-auto">
                            <img loading="lazy" src="/images/PM-person.webp" alt="Person reviewing documents on a tablet" className="object-cover h-full w-full" />
                        </div>
                        <div className="w-full md:w-2/3 bg-blue-50  flex-grow">
                            <InfoBar
                                text={`<span class="font-bold">Travel Leaders is a top-tier partner with virtually every travel provider</span>.
                                        Our clients benefit from discount rates thanks to the enormous market share of our global organization. We utilize all types of fares to drive costs savings for your travel program, including contractual, wholesale, proprietary and web fares.
                                        <div class="mt-4">Using sophisticated technologies, pricing software and qualified airline ticket wholesalers paired with our highly skilled travel advisors, we ensure significant cost savings.</div>`}
                                customCSS=""
                            />
                        </div>
                    </div>
                </div>
                {backToTop}
                <div className="my-4" id="implementation">
                    {titleBar('Implementation', 'TitleImplementation.webp')}
                    <InfoBar
                        text={`Travel Leaders is an expert at implementing programs with organizations ranging from large multi-nationals to small startups and everything in between. 
                               Working with your internal departments, we are responsible for <span class='font-bold'>the success of the implementation</span>,
                               including discovery of all travel-related information, ongoing project management, client meetings, communication plans and training strategies.
                               The various steps for complete travel program implementation follow the milestone sequence shown below:`}
                    />
                    <div className="block md:hidden mx-4">
                        <img
                            loading="lazy"
                            src="/images/ProgramMgmtArrowMob.webp"
                            alt="The 7 Steps to Program Implementation"
                            className="mx-auto"
                        />
                    </div>
                    <div className="hidden md:block mx-20">
                        <img
                            loading="lazy"
                            src="/images/ProgramMgmtArrowDsktp.webp"
                            alt="The 7 Steps to Program Implementation"
                            className="mx-auto"
                        />
                    </div>
                </div>
                {backToTop}
                <div className="my-4" id="meetings">
                    {titleBar('Meetings & Events', 'TitleMeetingsEvents.webp')}
                    <InfoImageBar
                        text=""
                        imageUrl="images/MeetingsEvents.webp"
                        imageAlt="Employees gathered at a conference table"
                        imageAsBG={false}
                    />
                    <div className="mx-4 md:mx-20 bg-blue-50 grid grid-cols-1 md:grid-cols-5 gap-4 p-4 -mt-8 mb-8 text-center">
                        {meetingGridItem(<PiMoney className="text-6xl mx-auto mb-4" />, 'Spending Too Much On Meetings?', 'By leveraging our company to assist with meetings management, we\'ll track, manage and control one of your companys\'s biggest expenses.')}
                        {meetingGridItem(<PiRepeat className="text-6xl mx-auto mb-4" />, 'Ensure your meetings run smoothly', 'Engage with our professional event planners for meetings large and small. No two events are equal, and our experienced leaders can handle all the logistics.')}
                        {meetingGridItem(<PiUsersThree className="text-6xl mx-auto mb-4" />, 'Group discounts ', 'Qualify as a group with as few as 10 people. Our partnerships, travel incentives & loyalty programs can result in valuable benefits.')}
                        {meetingGridItem(<PiBookOpen className="text-6xl mx-auto  mb-4" />, 'Tailored Experience', 'Our flexible solutions, top-flights vendors & easy-to-use technologies produce lively events that feature brand marketing, content development & on-site staff.')}
                        {meetingGridItem(<PiTarget className="text-6xl mx-auto mb-4" />, 'Reach your business goals', 'Perform beyond expectations with the help of our considerable experience & powerful partnerships. We\'re dedicated to supporting your growing company.')}
                    </div>
                </div>
                {backToTop}
                <div className="my-4" id="program">
                    {titleBar('Program Success', 'TitleProgramSuccess.webp')}
                    <InfoImageBar
                        text={programText}
                        imageUrl="images/ProgramSuccess.webp"
                        imageAlt="Two people shaking hands"
                        imageAsBG={false}
                    />
                </div>
                {backToTop}
                <div className="my-4" id="supplier">
                    {titleBar('Supplier Partnerships', 'TitleAccountProgramMgmt.webp')}
                    <div className="mx-4 md:mx-20 text-center md:text-left pb-4">
                        Our agency is a top-tier partner with virtually every travel service provider. Our clients benefit from the discounted rate we receive thanks to the enormous market share of our global organization.
                    </div>
                    {supplierInfo(<PiAirplaneTilt />, 'bg-prgmgmt-air.webp', 'Air', `We utilize all types of fares to drive cost savings for your travel program, including contractual, wholesale, proprietary and web fares. Using sophisticated technologies, pricing, software and qualified airline ticket wholesalers paired with our highly skilled travel advisors, 
                                                                              <span class="font-bold">we ensure significant cost savings</span>.`)}
                    {supplierInfo(<PiCar />, 'bg-prgmgmt-car.webp', 'Car', `Our organization has deeply discounted agreements with major rental car vendors that result in substantial savings for our clients. 
                                                                            <span class="font-bold">You can expect preferred pricing and inclusion</span> of ancillary services like direct billing and liability insurance.`)}
                    {supplierInfo(<PiBuildings />, 'bg-prgmgmt-hotel.webp', 'Hotel', `Available exclusively through our agency, the Worldwide Hotel Program offers Best Available Rates at over 42,000 global hotel brands and independent properties. Our proprietary global hotel program offers valuable benefits and rewards, including complimentary services and amenities, loyalty points and credit card guarantees.
                                                                             <div class="block pt-2"><span class="font-bold">The Travel Leader SELECT hotel program provides VIP-worthy privileges</span>
                                                                             at many of the world's most distinguished hotels, resorts and villas. Complimentary amenities include a US$100 credit, breakfast for two daily, room upgrade and early check in/late checkout based on availability.</div>`)}
                </div>
                {backToTop}
                <div className="my-4" id="sustainability">
                    {titleBar('Sustainability', 'TitleSustainability.webp')}
                    <div className="mx-2 my-8 md:mx-20 h-full flex justify-center items-stretch flex-col md:flex-row">
                        <div className="w-full md:w-1/3">
                            <img loading="lazy" src="/images/PM-sustainability.webp" alt="View of forest from above with lakes shaped in the recycle symobl" className="" />
                        </div>
                        <div className="w-full md:w-2/3 bg-blue-50">
                            <InfoBar
                                text={`<span class="font-bold">Travel Leaders recognizes the exponential need to reduce</span> our carbon footprint so we may preserve the world that we love to travel in. 
                                       We are proud to have a partnership with Sustainable Travel International <span class="font-bold">(sustainabletravel.org)</span>, a global organization dedicated to transforming tourism's impact on nature and people, to offer more sustainable solutions to support travelers through their climate mitigation journey.
                                       <div class="mt-4 md:w-1/2">
                                           <a href="/media/Climate-Friendly-Business-Travel-Tips.pdf" target="_blank" class="flex space-x-5 items-center justify-evenly px-3 rounded  transition duration-500 ease-in-out border border-gray-800 py-2 text-sm w-full text-white bg-gray-800 hover:bg-white hover:text-gray-800 text-white cursor-pointer">
                                            <span class=""><i class="fa-solid fa-file-pdf"></i></span>
                                            <span class="font-bold">Climate Friendly Business Travel Tips</span>
                                           </a>
                                       </div>
                                       <div class="mt-4">With a 15+ year track record of helping individuals and businesses mitigate their carbon footprint, Sustainable Travel International is leading the way towards a net zero future for travel and tourism. Through this collaborative partnership, <span class="font-bold">we aim to raise awareness around sustainable travel</span> and provide carbon offsets to help you safeguard the planet and balance out your trip emissions.</div>
                                       <div class="mt-4 md:w-1/2">
                                           <a href="/media/Travel-Better-Best-Practicies-Guide.pdf" target="_blank" class="flex space-x-5 items-center justify-evenly px-3 rounded  transition duration-500 ease-in-out border border-gray-800 py-2 text-sm w-full text-white bg-gray-800 hover:bg-white hover:text-gray-800 text-white cursor-pointer">
                                            <span class=""><i class="fa-solid fa-file-pdf"></i></span>
                                            <span class="font-bold">Travel Better Best Practices Guide</span>
                                           </a>
                                       </div>`}
                                customCSS=""
                            />
                        </div>
                    </div>
                </div>
                {backToTop}
                <div className="my-4" id="training">
                    {titleBar('Training', 'TitleTraining.webp')}
                    <InfoImageBar
                        text={trainingText}
                        imageUrl="images/PM-Training.webp"
                        imageAlt="5 people in a circle high fiving "
                        imageAsBG={false}
                    />
                </div>
                {backToTop}
                <div className="my-4" id="travel">
                    {titleBar('Travel Policy', 'TitleTravelPolicy.webp')}
                    <InfoImageBar
                        imageUrl="images/TravelPolicy.webp"
                        imageAlt="Reviewing Notes on a tablet and a laptop computer"
                        imageAsBG={false}
                        text="As the backbone of corporate travel program, the travel policy serves as the primary conveyance of corporate culture to business travelers. Whether it's a mandate or recommendation, homogeneous or varied by region, a single page or multiple, travel policies are as varied as the companies that implement them. From our experience, we know these policies need strong support in every level of of the organization; from C-level executives to mid-level managers to rank-and-file business travelers. We'll help you develop a travel policy that's the perfect fit for your unique situation."
                    />
                </div>
                {backToTop}
                <FeaturedAgency />
            </div>
        </>
    );
}

export default ProgramManagement;
