/* eslint-disable  import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileDropdown from './MobileDropdown';

const MobileMenuItems = ({ items, showMenu, setShowMenu }) => {
    const [dropdown, setDropdown] = useState(false);

    const closeDropdown = () => {
        dropdown && setDropdown(false);
        showMenu && setShowMenu(false);
    };

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setDropdown((prev) => !prev);
    };

    return (
        <>
            <li className="menu-items" onClick={closeDropdown}>
            {items.url && items.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                    >
                        <Link to={items.url} onClick={closeDropdown}>
                            {items.title}
                        </Link>
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? (
                                <span className="arrow-close" />
                            ) : (
                                    <span className="arrow" />
                                )}
                        </div>
                    </button>
                    <MobileDropdown
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : !items.url && items.submenu ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                    >
                        {items.title}
                        {' '}
                        <div onClick={(e) => toggleDropdown(e)}>
                            {dropdown ? (
                                <span className="arrow-close" />
                            ) : (
                                    <span className="arrow" />
                                )}
                        </div>
                    </button>
                    <MobileDropdown
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                        <Link to={items.url}>{items.title}</Link>
                    )}
            </li>
        </>
    );
};

export default MobileMenuItems;
