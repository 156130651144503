/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import InfoBar from '../components/InfoBar';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function ThankYou() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Thank you for contacting us',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    return (
        <>
            <Helmet>
                <title>Thank you for contacting us - Travel Leaders Business</title>
                <meta name="title" content="Thank you for contacting us - Travel Leaders Business" />
                <meta name="description" content="Thank you for reaching out to us in regards to your business travel needs. We will get back to you within 1-2 business days" />
            </Helmet>
            <Hero
                title="THANK YOU"
                upperText=""
                lowerText=""
                fileName="/images/hero-about1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`Thank you for reaching out to inquire about our business travel specialties and for considering our services for your needs.
                               We're thrilled to provide you with any information you require and address any questions you may have.
                               Our team is dedicated to ensuring that your business travel experience is seamless and tailored to your specific requirements.
                               Please don't hesitate to let us know how we can assist you further.
                               Rest assured, we will get back to you within 1 or 2 business days. We look forward to the opportunity to serve you!`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
            </div>
        </>
    );
}

export default ThankYou;
