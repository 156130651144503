/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiKey,
    PiShieldCheck,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function DutyOfCare() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
            url: '/technology',
        },
        {
            label: 'Duty Of Care',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    return (
        <>
            <Helmet>
                <title>Duty Of Care - Travel Leaders Business</title>
                <meta name="title" content="Duty Of Care - Travel Leaders Business" />
                <meta name="description" content="GuardianCare gives you the powerful assistance and innovative technology you need to keep your travelers updated and accounted for worldwide." />
            </Helmet>
            <Hero
                title="Duty Of Care"
                upperText=""
                lowerText=""
                fileName="/images/hero-dutyofcare1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`GuardianCare gives you the powerful assistance and innovative technology you need to keep your travelers updated and accounted for worldwide.
                               Powered by our partners at unsolved, GuardianCare combines reliable pre-trip risk advisories, global alerts, an interactive traveler dashboard and optional integration with our Connect app — all through one robust platform.
                                <h2 class="block mt-4 font-bold">Choose the service level that best fits your need:</h2>`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <div className="my-4">
                    <div className="flex flex-col md:flex-row items-start mx-4 gap-4 md:gap-12 md:mx-20 text-gray-800">
                        <div className="w-full p-4 my-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiKey />
                                </div>
                                <div className="w-full">
                                    <h3 className="block font-semibold py-2 tracking-wide">
                                        Standard Features
                                    </h3>
                                    <ul
                                        className="list-outside ml-6"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-2">Pre-trip risk advisory information and global alerts sent to travelers and travel managers</li>
                                        <li className="py-2">Interactive dashboard displaying traveler locations and global alerts</li>
                                        <li className="py-2">Emailed traveler alerts based on location</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="w-full p-4 my-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiShieldCheck />
                                </div>
                                <div className="w-full">
                                    <h3 className="block font-semibold py-2 tracking-wide">
                                       Premium Services
                                    </h3>
                                    <ul
                                        className="list-outside ml-6"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-2">Facilities integration with alerts</li>
                                        <li className="py-2">Mobile app for travelers to share location and safety status</li>
                                        <li className="py-2">Mobile app for non-travelers</li>
                                        <li className="py-2">Mass notifications for all employees</li>
                                        <li className="py-2">Emergency response service</li>
                                        <li className="py-2">Insurance</li>
                                        <li className="py-2">Executive protection</li>
                                        <li className="py-2">Evacuation services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <FeaturedAgency
                    technologyId="29"
                />
            </div>
        </>
    );
}

export default DutyOfCare;
