/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function UnusedTicketTracking() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
            url: '/technology',
        },
        {
            label: 'Unused Ticket Tracking',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    const titleBar = (title, image) => (
        <div
            className="flex flex-row mx-4 my-8 md:mx-20 p-4 text-white text-center text-3xl uppercase justify-center bg-gray-800"
            style={{ background: `url('/images/${image}')`, backgroundSize: 'cover' }}
        >
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Unused Ticket Tracking - Travel Leaders Business</title>
                <meta name="title" content="Unused Ticket Tracking - Travel Leaders Business" />
                <meta name="description" content="Maximize savings and efficiency with our cutting-edge unused airline ticket tracking system, tailored to streamline business travel expenses and ensure no opportunity for reimbursement is missed." />
            </Helmet>
            <Hero
                title="Unused Ticket Tracking"
                upperText=""
                lowerText=""
                fileName="/images/hero-unused-ticket-tracking1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`Unused airfare can be an expensive problem.
                               Travel Leaders utilizes a best-in-class application that verifies usage of booked airline and initiates refunds when allowed.
                               Our software searches itineraries with purchased airfare and pushes unused tickets to our automated refund processing tool.
                               If the airfare is non-refundable, the residual value is calculated and reminders are dropped both in your travel advisors desktop and traveler's profile for visibility.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <div className="my-4">
                    {titleBar('Utilize Airfare Credit', 'TitleUtilizeAirfareCredit.webp')}
                    <InfoBar
                        icon="clockClockwise"
                        iconPosition="left"
                        text={`When the next reservation is made either by phone or online, the traveler or travel arranger is prompted to utilize airfare credits.
                               Both parties are continually reminded of pending airline credits, their value and expiration dates during online and offline booking requests.
                               Additionally, a monthly or quarterly unused ticket summary report can be accessed.
                               Unused ticket tracking is addressed in our regular Travel Leaders account reviews, where we discuss unused ticket consumption and capital liability due to unused tickets.`}
                        customCSS="mx-4 my-8 md:mx-20"
                    />
                </div>
                <FeaturedAgency
                    technologyId="31"
                />
            </div>
        </>
    );
}

export default UnusedTicketTracking;
