/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import API from '../services/API';
import { getMoreReferralData, removeUTMCookies } from '../utils';

function EmailForm({ agency }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const history = useHistory();

    const {
        register, handleSubmit, watch, formState: { errors },
        setValue,
    } = useForm();

    const onSubmit = (data) => {
        setIsProcessing(true);
        API.sendEmailToAgency(data).then((response) => {
                removeUTMCookies();
                sessionStorage.setItem('user', data.email);
                history.push({
                    pathname: '/thankYou/',
                    search: `?check=${response.data.toLowerCase()}`,
                });
                setIsProcessing(false);
        });
    };

    let referralUrl = window.location.href;
    if (!referralUrl.toLowerCase().includes('utm_')) {
        referralUrl = window.location.href + getMoreReferralData();
    }

    useEffect(() => {
        setValue('agencyId', agency.agencyId.toString());
        setValue('profileId', agency.profileId.toString());
        setValue('contactType', 'email');
        setValue('URL', referralUrl);
    }, []);

    return (
        <div className="p-4 flex flex-col gap-6 max-w-2xl">

            <div className="text-lg font-semibold">
                {`Sending a message to ${agency.agencyName}`}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <input className="hidden" {...register('agencyId', { required: true })} />
                    <input className="hidden" {...register('profileId', { required: true })} />
                    <input className="hidden" {...register('contactType', { required: true })} />
                    <div>
                        <label>First Name <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="First Name" className="border border-gray-400  py-2 px-4 w-full" {...register('firstName', { required: true })} />
                    </div>
                    <div>
                        {errors.firstName && <span className="text-red-500 text-sm">First name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Last Name <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Last Name" className="border border-gray-400  py-2 px-4 w-full" {...register('lastName', { required: true })} />
                    </div>
                    <div>
                        {errors.lastName && <span className="text-red-500 text-sm">Last name is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Email <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Email" type="email" className="border border-gray-400  py-2 px-4 w-full" {...register('email', { required: true })} />
                    </div>
                    <div>
                        {errors.email && <span className="text-red-500 text-sm">Email is required</span>}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Phone Number <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Phone number" className="border border-gray-400  py-2 px-4 w-full" {...register('phone', { required: true })} />
                    </div>
                    {errors.phone && <span className="text-red-500 text-sm">Phone number is required</span>}
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Company Name <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Company Name" className="border border-gray-400  py-2 px-4 w-full" {...register('companyName', { required: true })} />
                    </div>
                    <div>
                        {errors.companyName && <span className="text-red-500 text-sm">Company name is required</span>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div>
                        <label>Zip Code <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <input placeholder="Zip Code" className="border border-gray-400  py-2 px-4 w-full" {...register('companyZip', { required: true })} />
                    </div>
                    {errors.companyZip && <span className="text-red-500 text-sm">Zip Code is required</span>}
                </div>

                <div className="flex flex-col">
                    <div>
                        <label>Your questions / comments for this Agency <span className="text-red-600">*</span></label>
                    </div>
                    <div>
                        <textarea placeholder="Comment" className="border border-gray-400  py-2 px-4 w-full" {...register('comment', { required: true })} />
                        {errors.comment && <span className="text-red-500 text-sm">Question/Comment is required</span>}

                    </div>
                </div>

                <div>
                    <button
                        disabled={isProcessing}
                        type="submit"
                        className={[' rounded py-2 px-10 text-white text-sm w-full',
                            isProcessing ? 'bg-gray-300' : 'bg-red-600'].join(' ')}
                    >
                        {
                            isProcessing ? `Sending email to ${agency.agencyName} ....`
                                : `Email ${agency.agencyName}`
                        }
                    </button>
                </div>
            </form>

        </div>

    );
}

EmailForm.propTypes = {
    agency: PropTypes.any.isRequired,

};
export default EmailForm;
