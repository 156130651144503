/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiAirplaneTilt,
    PiUserCircle,
    PiCalendarCheck,
    PiKey,
    PiShieldCheck,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function GlobalServices() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
            {
                label: 'Business Travel',
                url: '/business',
            },
            {
                label: 'Global Services',
            },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    return (
        <>
            <Helmet>
                <title>Global Travel Fulfillment - Travel Leaders Business</title>
                <meta name="title" content="Global Services - Travel Leaders Business" />
                <meta name="description" content="Our global network individually selects each local partner based on top factors that are  supported by local or regional account team" />
            </Helmet>
            <Hero
                title="Global Services"
                upperText=""
                lowerText=""
                fileName="/images/hero-globalservices1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-2 my-8 mx-4 md:mx-20 relative bg-white">
                        <div
                            className="flex  flex-col flex-nowrap relative w-full p2 md:p-8  bg-gray-800 text-white"
                            style={{ background: 'url(\'/images/bg-business.webp\')', backgroundSize: 'cover' }}
                        >
                            <div className="flex flex-row flex-nowrap items-center px-4 py-4 md:p-0">
                                <div className="pr-2 md:pr-8 text-2xl flex justify-center">
                                    <PiAirplaneTilt />
                                </div>
                                <div className="w-full font-medium uppercase py-2 tracking-wide text-left text-xl">
                                    Global Travel Fulfillment
                                </div>
                            </div>
                            <div className="md:mt-4 w-full text-left text-lg px-4 pb-4 md:p-0">
                                Our global network individually selects each local partner based on high operational standards. All are experienced, seasoned business travel experts with top-5 local market ranking. This level of service ensures your travelers are policy-compliant and protected around the world, while your data is being actively managed and accessible for your comprehensive reporting requirements.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 justify-between mx-4 md:mx-20 text-gray-800">
                        <div className="w-full p-4 my-8 p-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiUserCircle />
                                </div>
                                <div className="w-full">
                                    <span className="block font-semibold uppercase py-2 tracking-wide">
                                       Personalized Service
                                    </span>
                                    <ul
                                        className="-ml-8 pt-2"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-1">Trusted support in 80+ countries</li>
                                        <li className="py-1">Exceptional multilingual service</li>
                                        <li className="py-1">Small teams of agents dedicated to your account</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="w-full p-4 my-8 p-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiCalendarCheck />
                                </div>
                                <div className="w-full">
                                    <span className="block font-semibold uppercase py-2 tracking-wide">
                                        Data Consolidation
                                    </span>
                                    <ul
                                        className="-ml-8 pt-2"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-1">Compiled travel transactions with regular data quality audits and 100% global transparency</li>
                                        <li className="py-1">Localized guidance to power better decisions</li>
                                        <li className="py-1">Policy compliance, valuable supplier programs and closing program gaps</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="w-full p-4 my-8 p-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiKey />
                                </div>
                                <div className="w-full">
                                    <span className="block font-semibold uppercase py-2 tracking-wide">
                                       Global Account Management
                                    </span>
                                    <ul
                                        className="-ml-8 pt-2"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-1">Centralized coordination: global account manager supported by local or regional account team</li>
                                        <li className="py-1">Policy-compliant solutions</li>
                                        <li className="py-1">Maximized efficiency and cost-effectiveness</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="w-full p-4 my-8 p-4 md:p-8 bg-blue-50">
                            <div className="w-24 border-b-2 border-red-700 mb-2 md:-mt-2" />
                            <div className="flex flex-row flex-nowrap items-start">
                                <div className="px-2 text-4xl text-red-700 flex justify-center">
                                    <PiShieldCheck />
                                </div>
                                <div className="w-full">
                                    <span className="block font-semibold uppercase py-2 tracking-wide">
                                        Duty of care
                                    </span>
                                    <ul
                                        className="-ml-8 pt-2"
                                        style={{ listStyleType: 'circle' }}
                                    >
                                        <li className="py-1">Individual traveler monitoring worldwide with hands-on guidance available 24/7 </li>
                                        <li className="py-1">Alerts and advisory notifications when circumstances warrant</li>
                                        <li className="py-1">Emergency response services with local offices ready to assist</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20">
                        <h3 className="font-medium uppercase pb-4 tracking-wide text-center text-xl">Global Representation in all of the Following Countries</h3>
                        <img
                            src="/images/LocationMap.webp"
                            alt="Map Location of Travel Leaders Business Offices"
                            className="object-cover object-center w-full max-w-full"
                            loading="lazy"
                        />
                    </div>
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default GlobalServices;
