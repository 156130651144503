/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';
import BreadCrumbs from '../components/elements/BreadCrumbs';

function Customer() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    // DYNAMMIC PAGE CONTENT
    const pageContent = [
        {
            id: 'education',
            industryId: '12',
            title: 'Education',
            hero: 'hero-education.webp',
            image: 'body-education.webp',
            content: `We want to help you attain the highest program performance.
                      Whether for research, conferences, recruiting or athletics — we are your travel partners.
                      Our team understands the unique needs associated with higher education travel including but not limited to:
                      state contract compliance, multi-tier approval process, multiple travel policy capability, Fly America compliance, PCI compliance and travel policy enforcement.`,
        },
        {
            id: 'construction-engineering',
            industryId: '10',
            title: 'Construction & Engineering',
            hero: 'hero-construction.webp',
            image: 'body-construction.webp',
            content: `We want to help you build your business bigger and better than ever before.
                      We understand the diverse travel needs of the construction and the engineering fields from commercial to charter flights, even helicopter transfers, to get project stakeholders to a job or project site quickly and safely for the best price.
                      We are responsive and able to change travel arrangements in minutes.
                      With our experience and technologies, we are the experts who can build a customized business travel program to help your company control its costs.`,
        },
        {
            id: 'energy',
            industryId: '13',
            title: 'Energy/Oil/Gas',
            hero: 'hero-energy.webp',
            image: 'body-energy.webp',
            content: `We are professional travel managers who want to help you to control your company's travel costs.
                      We understand your unique industry needs for travel to offices worldwide, including remote locations from North America to Norway and Saudi Arabia to Qatar.
                      We have the expertise to get you the best group travel rates for your small or large crew installations.
                      We are easily accessible to re-accommodate travelers if weather conditions arise that will affect work.
                      We want to provide you with strategies and solutions to fuel your company's success.`,
        },
        {
            id: 'finance',
            industryId: '14',
            title: 'Financial Services',
            hero: 'hero-finance.webp',
            image: 'body-finance.webp',
            content: `Think of us as your team of expert advisors who will work to optimize your company’s travel budget.
                      Through our travel partners, including airlines and hotels, we can offer you special programs and pricing to help you save on, and better measure, your travel expenditures.
                      We are knowledgeable on your industry’s unique privacy and security needs.
                      We can offer your company exceptional service and a comprehensive travel management plan designed to improve your bottom line.`,
        },
        {
            id: 'government',
            industryId: '16',
            title: 'Government',
            hero: 'hero-government.webp',
            image: 'body-government.webp',
            content: `Our policy is your complete satisfaction.
                      With travel services tailored to the unique needs of federal, state and local government clients;
                      our experienced team can manage all aspects of official domestic and international travel. From Federal Travel Regulations to State Department Foreign Affairs to General Services Administration, our team will work diligently to ensure your travel complies with the city pairs contract program, the Fly America Act, the Open Skies agreement, government per diems, specific government travel policies or any additional travel guidelines you may have.`,
        },
        {
            id: 'healthcare',
            industryId: '33',
            title: 'Healthcare',
            hero: 'hero-healthcare.webp',
            image: 'body-healthcare.webp',
            content: `We want to help keep your profits healthy.
                      Our partnerships with the top travel providers — airlines, car rental companies, hotels and technology providers — can benefit your health care company's bottom line.
                      We are also group travel experts, helping your employees get to and from important educational conferences and training. 
                      Our technologies enable employees to collaborate with their colleagues, synchronized calendars and increase communication when teams are in multiple locations across the country or world.
                      We will diagnose your needs and provide you with a business travel program that will help your company become more fiscally fit.`,
        },
        {
            id: 'manufacturing',
            industryId: '18',
            title: 'Manufacturing',
            hero: 'hero-manufacturing.webp',
            image: 'body-manufacturing.webp',
            content: `We want to help your company become more productive. 
                      Whether you are traveling for research, production, a visit to headquarters, touring retail shops or production plants, or even for employee training, we are the team to help you get your job done more efficiently.
                      If your company is expanding into countries that pose a greater safety risk to your business travelers, we have the expertise to quickly resolve emergency issues.
                      We are dedicated to making your business travel program a success.`,
        },
        {
            id: 'media',
            industryId: '19',
            title: 'Media & Entertainment',
            hero: 'hero-media.webp',
            image: 'body-media.webp',
            content: `We will provide you with a personalized corporate travel management program that performs for your company.
                      We have the experience accommodating your unique travel needs, including extended stays and group transportation, offering you the best rates and your travelers added comfort.
                      From domestic and international flights to furnished apartments or home rental and car services, we will partner with you to create and manage a plan to help your company succeed.`,
        },
        {
            id: 'non-profit',
            industryId: '20',
            title: 'Non-profit',
            hero: 'hero-nonprofit.webp',
            image: 'body-nonprofit.webp',
            content: `We share your mission.
                      The requirements of non-profit travel are distinctive, and our team of travel experts has all the experience necessary to guide and support you through complex visa requirements, pre-trip medical needs, remote area travel and immediate disaster relief planning.
                      Our support does not end once your trip begins; we monitor your entire journey and ensure you return home safely.
                      Our mission is to deliver world class travel support so you can successfully deliver on yours.`,
        },
        {
            id: 'pharmaceutical',
            industryId: '21',
            title: 'Pharmaceutical Industry',
            hero: 'hero-pharmaceutical.webp',
            image: 'body-pharmaceutical.webp',
            content: `We will find a remedy for any travel issues your company suffers from.
                      We will offer you a travel program with both savings and benefits, including negotiating the best pricing through our strategic partnerships, innovative self-booking tools and measuring your program success.
                      With our expertise, we can also save your traveler's valuable time.
                      We know how to work within the constraints of food, beverage and hotel expenditure guidelines, and we stay on top of the constantly evolving regulatory changes that impact your employee's travels.`,
        },
        {
            id: 'professional',
            industryId: '22',
            title: 'Professional Services',
            hero: 'hero-professional.webp',
            image: 'body-professional.webp',
            content: `We want to offer you a professional personalized travel management program with exceptional service.
                      We understand the importance of meeting face-to-face with key decision makers to maximize your success.
                      We can make all of the travel arrangements, from start to finish.
                      Whether a private limo or hard-to-get restaurant reservation, luxury or budget, we can help your company save both valuable time and money.`,
        },
        {
            id: 'technology',
            industryId: '24',
            title: 'Technology',
            hero: 'hero-technology.webp',
            image: 'body-technology.webp',
            content: `We can help you stay ahead in today's tech savvy world.
                      We put advanced travel technologies at your fingertips to help manage travel itineraries, collaborate with colleagues and accept access critical flight information to save your travelers time and headaches.
                      Our in-depth reporting tools help you see in a glance any compliance issues.
                      We are always accessible to make sure your business travelers never have to waste time.
                      From online booking to realtime support, our team offers your company the tools and technologies to help you stay focused on growing your company's technologies.`,
        },
    ];

    const { id } = useParams();

    const page = pageContent.find((item) => item.id === id);
    // If page not found, render a default message
    if (!page) {
        return <div className="mx-4 md:mx-20 h-screen text-2xl pt-40 text-center">Sorry, this customer page does not exist.</div>;
    }
    // Destructure content from the page object
    const {
        industryId,
        title,
        hero,
        image,
        content,
    } = page;
    // END DYNAMIC PAGE CONTENT

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Customers',
            url: '/Customers',
        },
        {
            label: title,
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }

    return (
        <>
            <Helmet>
                <title>{`${title} - Travel Leaders Business`}</title>
                <meta name="title" content={`${title} - Travel Leaders Business`} />
                <meta name="description" content="At Travel Leaders Business we handle all typs of Customers, from construction and engineering to technology companies, we handle all types of business travel." />
            </Helmet>
            <Hero
                title={title}
                upperText=""
                lowerText=""
                fileName={`/images/${hero}`}
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20">
                        <span className="block font-semibold uppercase py-2 tracking-wide hidden">
                            Featured Travel Management Company
                        </span>
                        <div className="mt-4" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
                {/*<div className="my-4">*/}
                {/*    <InfoImageBar*/}
                {/*        title="And 1 More Thing"*/}
                {/*        text={`<span class="my-4">Travel Leaders Business | Travel Newsletter</span>*/}
                {/*               The travel industry moves quickly and it's hard to stay up to date.*/}
                {/*               Our editorial team selects and publishes the highest quality content from hundreds of articles to ensure your time is well spent.*/}
                {/*               Make and one more thing your daily source for timely and valuable business travel news.`}*/}
                {/*        iconPosition="top"*/}
                {/*        imageUrl={`/images/${image}`}*/}
                {/*        imageAsBG={false}*/}
                {/*        inverted={false}*/}
                {/*    />*/}
                {/*</div>*/}
                <FeaturedAgency
                    industryId={industryId}
                    searchTitle={`specializing in the ${title} sector`}
                />
            </div>
        </>
    );
}

export default Customer;
