/* eslint-disable prefer-template*/
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PiXCircle, PiCaretDoubleUp, PiCaretDoubleDown } from 'react-icons/pi';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { SlideDown } from 'react-slidedown';
import SearchBox from '../components/SearchBox';
import Loading from '../components/elements/Loading';
import useAppStore from '../contexts/useAppStore';
import BreadCrumbs from '../components/elements/BreadCrumbs';
import API from '../services/API';
import Button from '../components/elements/Button';
import {
    callMe,
    getStateName,
    modalCustomStyles,
} from '../utils';
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import 'react-toastify/dist/ReactToastify.css';



function Agencies() {
    const history = useHistory();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const [agencyData, setAgencyData] = useState(null);
    const [facetedStates, setFacetedStates] = useState(null);
    const [facetedIndustry, setFacetedIndustry] = useState(null);
    const [facetedLanguage, setFacetedLanguage] = useState(null);
    const [facetedTechnology, setFacetedTechnology] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedAgency, setSelectedAgency] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
        getIndustries,
    } = useAppStore();

    const [activeFilters, setActiveFilters] = useState({
        state: query.get('slctState') || '',
        industry: query.get('slctIndustry') || '',
        technology: query.get('slctTechnology') || '',
        language: query.get('slctLanguage') || '',
    });

    const [selectedState, setSelectedState] = useState(query.get('slctState') || '');
    const [selectedIndustry, setSelectedIndustry] = useState(query.get('slctIndustry') || '');
    const [selectedTechnology, setTechnology] = useState(query.get('slctTechnology') || '');
    const [selectedLanguage, setSelectedLanguage] = useState(query.get('slctLanguage') || '');
    const [dynamicTitle, setDynamicTitle] = useState('');

    const getMatchedAgents = async () => {
        setLoading(true);
        API.fetchAgencies({
            agencyState: selectedState || activeFilters.state,
            industry: selectedIndustry || activeFilters.industry,
            technology: selectedTechnology || activeFilters.technology,
            agencyId: 0,
            language: selectedLanguage || activeFilters.language,
            latitude: '',
            longitude: '',
            radius: 0,
            rows: 99,
        }).then(async (result) => {
            if (result.responseStatus === 1) {
                setAgencyData(result.data);
            } else {
                toast(result.error.message);
            }
            setLoading(false);
        });
    };

    const getFacetedStates = async () => {
        setLoading(true);
        API.fetchFaceted({
            state: selectedState || '',
            tech: selectedTechnology || '',
            industry: selectedIndustry || '',
            language: selectedLanguage || '',
            type: 'FacetedSearchStates',
        }).then(async (response) => {
            if (!response || response.error) {
                toast('Error Occured Loading States');
            } else {
                setFacetedStates(response);
            }
            setLoading(false);
        });
    };

    const getFacetedIndustry = async () => {
        setLoading(true);
        API.fetchFaceted({
            state: selectedState || '',
            tech: selectedTechnology || '',
            industry: selectedIndustry || '',
            language: selectedLanguage || '',
            type: 'FacetedSearchIndustry',
        }).then(async (response) => {
            if (!response || response.error) {
                toast('Error Occured Loading Industry');
            } else {
                setFacetedIndustry(response);
            }
            setLoading(false);
        });
    };

    const getFacetedLanguage = async () => {
        setLoading(true);
        API.fetchFaceted({
            state: selectedState || '',
            tech: selectedTechnology || '',
            industry: selectedIndustry || '',
            language: selectedLanguage || '',
            type: 'FacetedSearchLanguage',
        }).then(async (response) => {
            if (!response || response.error) {
                toast('Error Occured Loading Language');
            } else {
                setFacetedLanguage(response);
            }
            setLoading(false);
        });
    };

    const getFacetedTechnology = async () => {
        setLoading(true);
        API.fetchFaceted({
            state: selectedState || '',
            tech: selectedTechnology || '',
            industry: selectedIndustry || '',
            language: selectedLanguage || '',
            type: 'FacetedSearchTech',
        }).then(async (response) => {
            if (!response || response.error) {
                toast('Error Occured Loading Technology');
            } else {
                setFacetedTechnology(response);
            }
            setLoading(false);
        });
    };

    const [links, setLinks] = useState([{
        label: 'Home',
        url: '/',
    },
    {
        label: 'Find A Travel Expert',
        url: '/find-a-travel-expert',
    },
    {
        label: 'Results',

    },
    ]);

    const goToAgencyBio = (agency, section = '') => {
        let searchTerms = '';
        if (window.location.search.substr(1).length > 0) {
            searchTerms = '&' + window.location.search.substr(1);
        }
        history.push(`/agency/${agency.agencyId}?ref=search${searchTerms}`);
    };

    const goToSearchResults = () => {
        const filters = [];

        if (facetedState !== null) {
            filters.push(`slctState=${facetedState}`);
        }
        if (facetedInd !== null) {
            filters.push(`slctIndustry=${facetedInd}`);
        }
        if (facetedLng !== null) {
            filters.push(`slctLanguage=${facetedLng}`);
        }
        if (selectedCheckboxes !== null) {
            const techString = Object.keys(selectedCheckboxes).filter((key) => selectedCheckboxes[key]);
            filters.push(`slctTechnology=${techString}`);
        }

        history.push(`/agencies?${filters.join('&')}`);
    };

    const emailMe = (agency) => {
        setSelectedAgency(agency);
        setIsEmailFormModalOpen(true);
    };

    function getIndustryNameById(items, id) {
        // Filter the items array to find the item with the matching itemId
        const matchingItem = items.find((item) => item.itemId.toString() === id.toString());
        // If a matching item is found, return its IndustryName, otherwise return null
        return matchingItem ? matchingItem.itemName : '';
    }

    // get's the tech list and adds it to the title
    let techNames = null;
    if (selectedTechnology) {
        const techList = [
            { id: 32, value: 'After Hours Services' },
            { id: 30, value: 'Client Portals' },
            { id: 29, value: 'Duty of Care' },
            { id: 31, value: 'Expense Integration' },
            { id: 27, value: 'Online Booking Tools' },
            { id: 28, value: 'Reporting Suite' },
        ];
        const techListIds = selectedTechnology.split(',').map((id) => parseInt(id));
        const matchingTechItems = techList.filter((item1) => techListIds.includes(item1.id));
        techNames = matchingTechItems.map((item, index) => (
            <span key={item.id}>
                {index > 0 ? (index === matchingTechItems.length - 1 ? ' & ' : ', ') : ''}
                {item.value}
            </span>
        ));
    }
    // End

    const constructTitle = () => {
        let title = '';
        let agencyNum = '';
        let state = '';
        const industryName = getIndustryNameById(getIndustries(), selectedIndustry);
        const stateName = getStateName(selectedState);
        stateName.length > 1 ? state = `in ${stateName}` : '';
        agencyData?.agency.length > 1 ? agencyNum = 'Agencies' : agencyNum = 'Agency';
        if (dataLoaded) {
            title = `Found ${agencyData?.agency.length} ${industryName} Travel ${agencyNum} ${state}`;
            if (agencyData?.agency.length === 0) {
                title = `Sorry, we found no ${industryName} Travel Agencies ${state}`;
            }
            setDynamicTitle(title);
        }
    };

    const setSelectedOptions = () => {
        if (dataLoaded) {
            if (selectedState) {
                setFacetedState(selectedState);
            }
            if (selectedIndustry) {
                setFacetedInd(selectedIndustry);
            }
            if (selectedLanguage) {
                setFacetedLng(selectedLanguage);
            }
        }
    };

    const closeModal = () => {
        closeFormModal();
    };

    useEffect(() => {
        getMatchedAgents();
        getFacetedStates();
        getFacetedIndustry();
        getFacetedLanguage();
        getFacetedTechnology();
        setDataLoaded(true);
    }, []);

    useEffect(() => {
        constructTitle();
        setSelectedOptions();
    }, [agencyData]);

    const [facetedState, setFacetedState] = useState(null);
    const [facetedInd, setFacetedInd] = useState(null);
    const [facetedLng, setFacetedLng] = useState(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [showFullState, setShowFullState] = useState(false);
    const [showFullInd, setShowFullInd] = useState(false);
    const [showFullLng, setShowFullLng] = useState(false);
    const [showFullTech, setShowFullTech] = useState(false);

    const handleChangeStateSelected = (event) => {
        setFacetedState(event.target.value);
    };
    const handleChangeIndustrySelected = (event) => {
        setFacetedInd(event.target.value);
    };
    const handleChangeLanguageSelected = (event) => {
        setFacetedLng(event.target.value);
    };

    const handleChangeTechnologySelected = (event) => {
        const { name, checked } = event.target;
        setSelectedCheckboxes({
            ...selectedCheckboxes,
            [name]: checked,
        });
    };

    /*HIDE OR SHOW THE MOBILE FACETED SEARCH BASED ON SCREENSIZE */
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let hideSearch = true;
    if (windowSize.width < 768) { hideSearch = false; }
    const [showDiv, setShowDiv] = useState(hideSearch);
    const [filter, setFilter] = useState(false);
    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };
    /*END HIDE OR SHOW THE MOBILE FACETED SEARCH BASED ON SCREENSIZE */
    return (
        <>
            <Helmet>
                <title>{`${dynamicTitle} | Travel Leaders Business`}</title>
                <meta name="title" content={`${dynamicTitle} | Travel Leaders Business`} />
                <meta name="description" content="Find your ideal business travel company with our comprhensive search, delivering the perfect match for your corporate needs." />
            </Helmet>
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="mt-4 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <div className="mx-4 md:mx-20 grid grid-cols-1 md:grid-cols-12 gap-x-4">
                        <div className="col-span-12 md:col-span-9 order-2 md:order-1">
                        {agencyData && (
                                <>
                                    <div className="my-4">
                                        <div className="text-2xl uppercase text-gray-800 font-bold">
                                            {dynamicTitle}
                                            {(techNames && dataLoaded) ? (
                                                <>
                                                    {' working with '}
                                                    {techNames}
                                                </>
                                            ) : ''}
                                        </div>
                                    </div>
                                    {agencyData?.agency.map((agency, index) => (
                                        <div
                                            key={agency.agencyId}
                                            className="flex gap-2 w-full justify-between items-top flex-col md:flex-row my-4 md:my-8 p-4 md:p-8 bg-blue-50"
                                        >
                                            <div className="w-full md:w-3/5 pb-6 md:pb-0">
                                                <div className="w-full h-full flex items-center md:hidden">
                                                    <img
                                                        loading="lazy"
                                                        className="mx-auto rounded-xl w-48 h-48 object-cover overflow-hidden hover:scale-105 transform ease-in-out duration-1000 cursor-pointer border hover:border-red-700"
                                                        src={agency.profileImage}
                                                        alt={agency.agencyName}
                                                        onClick={() => goToAgencyBio(agency)}
                                                    />
                                                </div>
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0" />
                                                <div className="w-full text-lg font-semibold uppercase text-gray-800 text-left pb-2 md:pb-4">
                                                    {agency.agencyName}
                                                </div>
                                                <div className="w-full font-medium text-gray-800 pb-2 md:pb-4">
                                                    {agency.profileTitle}
                                                </div>
                                                <div className="w-full text-red-700 pb-2 pb-2 md:pb-4">
                                                    {agency.city}
                                                    {', '}
                                                    {agency.state}
                                                </div>
                                                <div className="w-full pb-2 md:pb-4">
                                                    {agency.profileOverview}
                                                </div>
                                                <div className="w-full pb-2 md:pb-4">
                                                    <span className="block text-gray-800 text-xl mb-4">
                                                        Our Specialities
                                                    </span>
                                                    <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-1">
                                                        {agency.industry && (
                                                            <>
                                                                {agency.industry && agency.industry.map((x) => (
                                                                    <div className="" key={x.indId}>
                                                                      {x.industryName}
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="w-full md:pb-4">
                                                    <Button
                                                        title="Learn More"
                                                        padding="py-4 font-semibold"
                                                        onClick={() => goToAgencyBio(agency)}
                                                        width="w-full md:w-1/2"
                                                    />
                                                </div>
                                            </div>

                                            <div className="w-full md:w-2/5 pb-6 md:pb-0">
                                                <div className="grid grid-cols-2 gap-4 pt-4 md:pt-0">
                                                    <div className="col-span-1">
                                                        <Button
                                                            title="Call Us"
                                                            icon="phone"
                                                            padding="py-2"
                                                            onClick={() => callMe(agency, () => {
                                                                setSelectedAgency(agency);
                                                                setIsPhoneFormModalOpen(true);
                                                            })}
                                                            inverted
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Button
                                                            title="Email Us"
                                                            icon="email"
                                                            padding="py-2"
                                                            onClick={() => emailMe(agency)}
                                                            inverted
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-full h-full items-center hidden md:flex">
                                                    <img
                                                        loading="lazy"
                                                        className="mx-auto rounded-xl w-48 h-48 object-cover overflow-hidden hover:scale-105 transform ease-in-out duration-1000 cursor-pointer border hover:border-red-700"
                                                        src={agency.profileImage}
                                                        alt={agency.agencyName}
                                                        onClick={() => goToAgencyBio(agency)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                        )}
                        </div>
                        <div className="md:block col-span-12 md:col-span-3 order-1 md:order-2">
                            <div className="text-xl font-bold mb-4">
                                <span className="hidden md:block">Refine Results</span>
                                <Button
                                    title={filter ? 'HIDE FILTERS' : 'SHOW FILTERS'}
                                    padding="py-4 font-semibold"
                                    onClick={() => { setFilter(!filter); toggleDiv(); }}
                                    width="block w-full md:w-1/2 md:hidden"
                                />
                            </div>
                            {showDiv && (
                                <form id="faceted-form">
                                    {facetedStates && (
                                        <>
                                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0" />
                                            <div className="text-lg">Agency Location</div>
                                            <SlideDown className="my-dropdown-slidedown">
                                                {facetedStates.slice(0, showFullState ? facetedStates.length : 10).map((x) => (
                                                    <div
                                                        key={x.state}
                                                        className="mx-2 my-2"
                                                    >
                                                        <input
                                                            key={x.state}
                                                            id={x.state}
                                                            name="slctState"
                                                            type="radio"
                                                            value={x.state}
                                                            checked={facetedState === x.state}
                                                            onChange={handleChangeStateSelected}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={x.state}
                                                        >
                                                            {getStateName(x.state)}
                                                        </label>
                                                    </div>
                                                ))}
                                            </SlideDown>
                                            {facetedStates.length > 10
                                                && (
                                                    <div className="mb-2 cursor-pointer text-red-700 hover:text=gray-900 mt-2" onClick={() => setShowFullState(!showFullState)}>
                                                        {!showFullState ? (
                                                            <div className="flex flex-row">
                                                                <div className="">
                                                                    Show More
                                                                {` (${facetedStates.length - 5})`}
                                                                </div>
                                                                <div className="ml-2">
                                                                    <PiCaretDoubleDown />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="flex flex-row">
                                                                    <div className="">
                                                                        Close
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <PiCaretDoubleUp />
                                                                    </div>

                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                    {facetedIndustry && (
                                        <>
                                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0" />
                                            <div className="text-lg">Industry</div>
                                            <SlideDown className="my-dropdown-slidedown">
                                                {facetedIndustry.slice(0, showFullInd ? facetedIndustry.length : 10).map((x) => (
                                                    <div
                                                        key={x.itemId}
                                                        className="mx-2 my-2"
                                                    >
                                                        <input
                                                            id={x.itemName}
                                                            name="slctIndustry"
                                                            type="radio"
                                                            value={x.itemId}
                                                            checked={facetedInd === x.itemId.toString()}
                                                            onChange={handleChangeIndustrySelected}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={x.itemName}
                                                        >
                                                            {x.itemName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </SlideDown>
                                            {facetedIndustry.length > 10
                                                && (
                                                    <div className="mb-2 cursor-pointer text-red-700 hover:text=gray-900 mt-2" onClick={() => setShowFullInd(!showFullInd)}>
                                                        {!showFullInd ? (
                                                            <div className="flex flex-row">
                                                                <div className="">
                                                                    Show More
                                                                {` (${facetedIndustry.length - 5})`}
                                                                </div>
                                                                <div className="ml-2">
                                                                    <PiCaretDoubleDown />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="flex flex-row">
                                                                    <div className="">
                                                                        Close
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <PiCaretDoubleUp />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                    {facetedTechnology && (
                                        <>
                                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0" />
                                            <div className="text-lg">Technology</div>
                                            <SlideDown className="my-dropdown-slidedown">
                                                {facetedTechnology.slice(0, showFullTech ? facetedTechnology.length : 10).map((x) => (
                                                    <div
                                                        key={x.itemId}
                                                        className="mx-2 my-2"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            id={x.itemId}
                                                            name={x.itemId}
                                                            value={x.itemId}
                                                            checked={selectedCheckboxes[x.itemId] || false}
                                                            onChange={handleChangeTechnologySelected}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={x.itemId}
                                                        >
                                                            {x.itemName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </SlideDown>
                                            {facetedTechnology.length > 10
                                                && (
                                                    <div className="mb-2 cursor-pointer text-red-700 hover:text=gray-900 mt-2" onClick={() => setShowFullTech(!showFullTech)}>
                                                        {!showFullTech ? (
                                                            <div className="flex flex-row">
                                                                <div className="">
                                                                    Show More
                                                                {` (${facetedTechnology.length - 5})`}
                                                                </div>
                                                                <div className="ml-2">
                                                                    <PiCaretDoubleDown />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="flex flex-row">
                                                                    <div className="">
                                                                        Close
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <PiCaretDoubleUp />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                    {facetedLanguage && (
                                        <>
                                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-0" />
                                            <div className="text-lg">Second Language</div>
                                            <SlideDown className="my-dropdown-slidedown">
                                                {facetedLanguage.slice(0, showFullLng ? facetedLanguage.length : 10).map((x) => (
                                                    <div
                                                        key={x.itemId}
                                                        className="mx-2 my-2"
                                                    >
                                                        <input
                                                            id={x.itemName}
                                                            name="slctLanguage"
                                                            type="radio"
                                                            value={x.itemId}
                                                            checked={facetedLng === x.itemId.toString()}
                                                            onChange={handleChangeLanguageSelected}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={x.itemName}
                                                        >
                                                            {x.itemName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </SlideDown>
                                            {facetedLanguage.length > 10
                                                && (
                                                    <div className="mb-2 cursor-pointer text-red-700 hover:text=gray-900 mt-2" onClick={() => setShowFullLng(!showFullLng)}>
                                                        {!showFullLng ? (
                                                            <div className="flex flex-row">
                                                                <div className="">
                                                                    Show More
                                                                {` (${facetedLanguage.length - 5})`}
                                                                </div>
                                                                <div className="ml-2">
                                                                    <PiCaretDoubleDown />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                                <div className="flex flex-row">
                                                                    <div className="">
                                                                        Close
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <PiCaretDoubleUp />
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                )}
                                        </>
                                    )}
                                    <div className="w-full my-4">
                                        <Button
                                            title="Search"
                                            padding="py-4 font-semibold"
                                            onClick={() => goToSearchResults()}
                                            width="w-full md:w-1/2"
                                        />
                                        <Link
                                            to="/agencies"
                                            className="my-2 block tracking-wide underline underline-offset hover:text-red-600"
                                        >
                                            Reset Search
                                        </Link>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>

             <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
             >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <PiXCircle className="text-red-500 text-right text-xl" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agency={selectedAgency} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agency={selectedAgency}
                            phone={selectedAgency.phone}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
             </Modal>
            {loading && <Loading />}
        </>
    );
}

export default Agencies;
