/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PiXCircle } from 'react-icons/pi';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Loading from '../components/elements/Loading';
import API from '../services/API';
import Button from '../components/elements/Button';
import {
    callMe,
    modalCustomStyles,
} from '../utils';
import useAppStore from '../contexts/useAppStore';
import BreadCrumbs from '../components/elements/BreadCrumbs';
import EmailForm from '../components/EmailForm';
import PhoneForm from '../components/PhoneForm';
import 'react-toastify/dist/ReactToastify.css';

function Agency() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState();
    const [links, setLinks] = useState([]);
    const [location, setLocation] = useState([]);
    const [data, setData] = useState();
    const [selectedAgency, setSelectedAgency] = useState();
    const {
        setIsEmailFormModalOpen, setIsPhoneFormModalOpen,
        isEmailFormModalOpen, isPhoneFormModalOpen, closeFormModal,
    } = useAppStore();

    const getAgency = () => {
        setLoading(true);
        API.getAgencyInfo({
            agencyId: id,
            preview: false,
        }).then((result) => {
            if (result.responseStatus === 1) {
                setData(result.data);
            } else { toast(result.error.message); }
            setLoading(false);
        });
    };

    const emailMe = (agency) => {
        setSelectedAgency(agency);
        setIsEmailFormModalOpen(true);
    };

    const closeModal = () => {
        closeFormModal();
    };

    useEffect(() => {
        getAgency();
    }, [currentPage]);

    useEffect(() => {
        if (data) {
            const base = [{
                label: 'Home',
                url: '/',
            },
            {
                label: 'Find A Travel Expert',
                url: '/find-a-travel-expert',
            }];
            base.push({
                label: `${data[0].agencyName}`,
            });

            setLinks(base);

            const baseLocation = {
                lat: data[0].latitude,
                lng: data[0].longitude,
            };
            setLocation(baseLocation);
        }
    }, [data]);

    const MyMapComponent = withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: location.lat, lng: location.lng }}
        >
            {props.isMarkerShown && <Marker position={{ lat: location.lat, lng: location.lng }} />}
        </GoogleMap>
    ));

    function getRandom(myData) {
        const randomIndex = Math.floor(Math.random() * myData.length);
        return randomIndex;
    }

    return (
        <>
            <ToastContainer />
            {data
                && (
                <>
                    {data.map((x, index) => (
                        <div key={index}>
                            <Helmet>
                                <title>
                                    {`${x.agencyName} ${x.city}, ${x.state} Travel Agency | Travel Leaders Business`}
                                </title>
                                <meta name="title" content={`${x.agencyName} ${x.city}, ${x.state} Travel Agency  | Travel Leaders Business`} />
                                <meta name="description" content="Discover how Travel Leaders Business combines industry expertise with personalized service to streamline your business travel needs efficiently and cost-effectively." />
                            </Helmet>
                            <div className="min-h-screen max-w-screen-xl mx-auto">
                                <div className="my-4">
                                    <BreadCrumbs links={links} />
                                </div>
                                <div className="my-4">
                                    <div className="mx-4 md:mx-20">
                                        <div className="flex flex-col md:flex-row my-8">
                                            <div className="w-full md:w-1/4 mr-0 md:mr-8">
                                                <img
                                                    src={x.profileImage}
                                                    alt={`${x.agencyName} ${x.imgContentName}`}
                                                    loading="lazy"
                                                    className="w-full max-w-xs"
                                                />
                                            </div>
                                            <div className="w-full md:w-2/4">
                                                <h2 className="block uppercase text-2xl font-bold mb-2">{x.agencyName}</h2>
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                {x.imgContentName ? (
                                                    <h3 className="block text-lg font-semibold">
                                                        {x.imgContentName}
                                                    </h3>
                                                ) : ''}
                                                <div className="block my-2">
                                                    {`Business Travel Management, ${x.city}, ${x.state}`}
                                                </div>
                                                {x.imgContentTitle ? (
                                                    <span className="block italic">
                                                        {x.imgContentTitle}
                                                    </span>
                                                ) : ''}
                                                <div className="grid grid-cols-2 gap-4 mt-8">
                                                    <div className="col-span-1">
                                                        <Button
                                                            title="Call Us"
                                                            icon="phone"
                                                            padding="py-2"
                                                            onClick={() => callMe(x, () => {
                                                                setSelectedAgency(x);
                                                                setIsPhoneFormModalOpen(true);
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <Button
                                                            title="Email Us"
                                                            icon="email"
                                                            padding="py-2"
                                                            onClick={() => emailMe(x)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <h2 className="block mb-4 text-lg">
                                                {x.profileTitle}
                                            </h2>
                                            <span className="block" dangerouslySetInnerHTML={{ __html: x.profileBio }} />
                                        </div>
                                        <div className="flex flex-col md:flex-row bg-blue-50 p-8 my-8">
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">Industries We Serve:</h3>
                                                <ul
                                                    className="list-outside ml-6"
                                                    style={{ listStyleType: 'circle' }}
                                                >
                                                    {x.industry.map((item) => (
                                                        <li
                                                            className="py-2"
                                                            key={item.itemId}
                                                        >
                                                            {item.itemName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">Technologies We Use:</h3>
                                                <ul
                                                    className="list-outside ml-6"
                                                    style={{ listStyleType: 'circle' }}
                                                >
                                                    {x.technology.map((item) => (
                                                        <li
                                                            className="py-2"
                                                            key={item.itemId}
                                                        >
                                                            {item.itemName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">Service Level:</h3>
                                                <ul
                                                    className="list-outside ml-6"
                                                    style={{ listStyleType: 'circle' }}
                                                >
                                                    {x.fullService ? (<li className="py-2">Full Service</li>) : ''}
                                                    {x.onlineBooking ? (<li className="py-2">Online Booking</li>) : ''}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="flex flex-col md:flex-row bg-blue-50 p-8 my-8">
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">Languages We Speak:</h3>
                                                <ul
                                                    className="list-outside ml-6"
                                                    style={{ listStyleType: 'circle' }}
                                                >
                                                    <li>English</li>
                                                    {x.languages.map((item) => (
                                                        <li
                                                            className="py-2"
                                                            key={item.languageId}
                                                        >
                                                            {item.language}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">We Are:</h3>
                                                <ul
                                                    className="list-outside ml-6"
                                                    style={{ listStyleType: 'circle' }}
                                                >
                                                    {x.classType.map((item) => (
                                                        <li
                                                            className="py-2"
                                                            key={item.itemId}
                                                        >
                                                            {item.itemName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {x.latitude ? (
                                                <div className="w-full md:w-1/3">
                                                    <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                    <h3 className="text-lg font-bold py-2">Visit Us:</h3>
                                                    <MyMapComponent
                                                        isMarkerShown
                                                        loadingElement={<div style={{ height: '100%' }} />}
                                                        containerElement={<div style={{ height: '250px' }} />}
                                                        mapElement={<div style={{ height: '100%' }} />}
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>

                                        <div className="flex flex-col md:flex-row bg-blue-50 p-8 my-8">
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">
                                                    {x.agencyName}
                                                </h3>
                                                <div className="py-4">
                                                    {`${x.address} ${x.city}, ${x.state}`}
                                                </div>
                                                <div className="py=4">
                                                    <Button
                                                        title="Call Us"
                                                        icon="phone"
                                                        padding="py-2"
                                                        width="w-32"
                                                        onClick={() => callMe(x, () => {
                                                            setSelectedAgency(x);
                                                            setIsPhoneFormModalOpen(true);
                                                        })}
                                                        inverted
                                                    />
                                                </div>
                                            </div>
                                            {x.agencyBranches.length > 0 ? (
                                                <div className="w-full md:w-1/3">
                                                    <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                    <h3 className="text-lg font-bold py-2">Additional Locations:</h3>
                                                    <ul
                                                        className="list-outside ml-6"
                                                        style={{ listStyleType: 'disc' }}
                                                    >
                                                        {x.agencyBranches.map((agency) => (
                                                            <li
                                                                className="py-2"
                                                                key={agency.agencyId}
                                                            >
                                                                {`${agency.city}, ${agency.state}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : ''}
                                            <div className="w-full md:w-1/3">
                                                <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                                                <h3 className="text-lg font-bold py-2">Affiliations:</h3>
                                                <div className="flex flex-row">
                                                    {x.displayASTA ? (<span className="py-2 w-1/2"><img src="/images/logo-asta.webp" alt="American Society of Travel Advisors (ASTA)" /></span>) : ''}
                                                    {x.displayGBTA ? (<span className="py-2 w-1/2"><img src="/images/logo-gbta.webp" alt="Global Business Travel Association (GBTA)" /></span>) : ''}
                                                    {x.displayACTE ? (<span className="py-2 w-1/2"><img src="/images/logo-acte.webp" alt="Association of Corporate Travel Executives (ACTE)" /></span>) : ''}
                                                </div>
                                            </div>
                                        </div>

                                        {x.testimonials.length > 0 ? (
                                            <div className="p-8 my-8">
                                                <h2 className="text-lg font-bold py-2">Testimonials:</h2>
                                            {console.log(x)}
                                                {x.testimonials.map((review, i) => (
                                                <div
                                                    className="py-4"
                                                    key={i}
                                                >
                                                    <span className="block font-bold py-2">
                                                        {`"${review.title}"`}
                                                    </span>
                                                    {review.testimonial}
                                                    <span className="block py-2 italic">
                                                        {review.credit}
                                                    </span>
                                                </div>
                                            ))}
                                            </div>
                                        ) : ''}

                                        {x.evpAgents.length > 0 ? (
                                            <div className="flex flex-col md:flex-row bg-blue-50 p-8 my-8">
                                                <div className="w-full md:w-1/5">
                                                    {x.evpAgents.map((evpAgent, y) => (
                                                        <div key={y}>
                                                            <img
                                                                src={evpAgent.agentPhoto}
                                                                alt={`${evpAgent.firstName} ${evpAgent.lastName}`}
                                                                className=""
                                                                loading="lazy"
                                                            />
                                                            <span className="block text-base font-bold text-red-700 py-2">
                                                                {`${evpAgent.firstName} ${evpAgent.lastName}`}
                                                            </span>
                                                            <span className="block text-sm">
                                                                {evpAgent.jobTitle}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="w-full md:w-4/5 md:pl-8">
                                                    <span className="block font-bold mb-4">
                                                        Planning some time away from the office?
                                                    </span>
                                                    <p>As a business professional you recognize the importance of time and finding a great value. When it comes to getting away from the office let the travel experts at travel leaders use their knowledge and connections to offer exclusive packages and amenities.</p>
                                                    <p>From extending your business trip to take in the local scene to putting together a comprehensive family vacation, we can take your individual tastes and ideas and scope them into an unforgettable trip.</p>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                )}
            <Modal
                isOpen={isEmailFormModalOpen || isPhoneFormModalOpen}
                onRequestClose={() => closeModal()}
                style={modalCustomStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="z-50">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeModal()}>
                        <PiXCircle className="text-red-500 text-right text-xl" />
                    </div>
                    {isEmailFormModalOpen && <EmailForm agency={selectedAgency} />}
                    {isPhoneFormModalOpen && (
                        <PhoneForm
                            agency={selectedAgency}
                            phone={selectedAgency.phoneTollFree ? selectedAgency.phoneTollFree : selectedAgency.phoneLocal}
                            callBack={() => {
                                setIsPhoneFormModalOpen(false);
                                setIsEmailFormModalOpen(true);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {loading && <Loading />}
        </>

    );
}

export default Agency;
