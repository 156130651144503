import React, { useEffect, useRef, useState } from 'react';
import Hamburger from 'hamburger-react';
import { Link } from 'react-router-dom';
import { menuItemsData } from '../menuItemsData';
import MobileMenuItems from './MobileMenuItems';

const MobileNav = () => {
    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef();
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        const handler = (event) => {
            if (showMenu && ref.current && !ref.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [showMenu]);

    return (
        <nav className="mobile-nav">
            {/*<button*/}
            {/*    className="mobile-nav__menu-button"*/}
            {/*    type="button"*/}
            {/*    onClick={() => setShowMenu((prev) => !prev)}*/}
            {/*>*/}
            {/*    Menu*/}
            {/*</button>*/}
            <Hamburger toggled={isOpen} toggle={setOpen} />
            {isOpen && (
                <ul className="menus" ref={ref}>
                    <li className="menu-items">
                        <Link
                            to="/find-a-travel-expert"
                            className="text-red-600 font-bold hover:text-red-900"
                        >
                            Find A Travel Expert
                        </Link>
                    </li>
                    {menuItemsData.map((menu, index) => (
                        <MobileMenuItems
                            items={menu}
                            key={index}
                            showMenu={showMenu}
                            setShowMenu={setShowMenu}
                        />
                    ))}
                </ul>
            )}
        </nav>
    );
};

export default MobileNav;
