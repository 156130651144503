/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { useState } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TLLogoInvert } from '../utils';

function Footer() {
    return (
        <div>

            <div className="flex items-end w-full  bg-white relative">

                <div className="w-full text-white">
                    <div className="flex flex-col flex-wrap mx-auto  items-center">
                        <div
                            className="bg-gray-900 w-full px-8 pt-10 full-length"
                            style={{ background: 'url(\'/images/footer1.webp\')', backgroundSize: 'cover', backgroundPosition: '12%' }}
                        >
                            <div className="block md:flex w-full mb-8">
                                <div className="flex items-center">
                                    <img loading="lazy" alt="" src={TLLogoInvert} className="mx-auto" />
                                </div>
                                <div className="md:flex items-center text-xs text-center md:text-base">
                                    &nbsp;&nbsp;&mdash;&nbsp;&nbsp; Simplifying the Complexities of Business Travel
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-5 gap-2 justify-items-center text-center md:text-left text-gray-300">
                                <div className="col-span-1">
                                    <Link
                                        to="/find-a-travel-expert"
                                        className="font-medium tracking-wide underline underline-offset hover:text-red-600"
                                    >
                                        <div className="flex text-lg">
                                            <div className="flex items-center pr-1 text-2xl hidden md:block">
                                                <IoSearchSharp />
                                            </div>
                                            <div className="flex items-center">
                                                    Find a Travel Expert
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-span-1">
                                    <Link
                                        to="/business"
                                        className="font-medium text-lg tracking-wide underline underline-offset hover:text-red-600"
                                    >
                                        Business Travel
                                    </Link>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/business/global-services" className="hover:text-red-600">Global Services</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/business/program-management" className="hover:text-red-600">Program Management</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/business/risk-management" className="hover:text-red-600">Risk Management</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/business/traveler-experience" className="hover:text-red-600">Traveler Experience</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <Link
                                        to="/customers"
                                        className="font-medium text-lg tracking-wide underline underline-offset hover:text-red-600"
                                    >
                                        Customers
                                    </Link>
                                    <div className="md:grid grid-cols-2 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/construction-engineering" className="hover:text-red-600">Construction & Engineering</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/education" className="hover:text-red-600">Education</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/energy" className="hover:text-red-600">Energy/Oil/Gas</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/finance" className="hover:text-red-600">Financial Services</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/government" className="hover:text-red-600">Government</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/healthcare" className="hover:text-red-600">Healthcare</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/legal" className="hover:text-red-600">Legal</Link></p>
                                        </div>
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/manufacturing" className="hover:text-red-600">Manufacturing</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/media" className="hover:text-red-600">Media & Entertainment</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/non-profit" className="hover:text-red-600">Non-Profit</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/pharmaceutical" className="hover:text-red-600">Pharmaceutical Industy</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/professional" className="hover:text-red-600">Professional Services</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/customers/technology" className="hover:text-red-600">Technology</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <Link
                                        to="/technology"
                                        className="font-medium text-lg tracking-wide underline underline-offset hover:text-red-600"
                                    >
                                        Technology Solutions
                                    </Link>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/technology/duty-of-care" className="hover:text-red-600">Duty of Care</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/technology/online-booking-tools" className="hover:text-red-600">Online Booking Tools</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/technology/reporting" className="hover:text-red-600">Reporting</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/technology/unused-ticket-tracking" className="hover:text-red-600">Unused Ticket Tracking</Link></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><Link to="/technology/virtual-payments" className="hover:text-red-600">Virtual Payments</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <Link
                                        to="/about"
                                        className="font-medium text-lg tracking-wide underline underline-offset hover:text-red-600"
                                    >
                                        About Us
                                    </Link>
                                    <div className="md:grid grid-cols-1 gap-1">
                                        <div className="col-span-1">
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="https://internova.com/terms-conditions/" target="_blank" rel="noreferrer" className="hover:text-red-600">Terms</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight"><a href="https://internova.com/privacy-policy/" target="_blank" rel="noreferrer" className="hover:text-red-600">Privacy</a></p>
                                            <p className="text-sm p-0 mx-0 my-2 leading-tight">
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        openAxeptioCookies();
                                                    }}
                                                >
                                                    Manage Cookie Preferences
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-8 text-center tracking-wide text-gray-300">
                                &copy;
                                {' '}
                                {moment().format('YYYY')}
                                {' '}
                                Travel Leaders Franchise Group, LLC.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;
