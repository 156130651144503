export const menuItemsData = [
    {
        title: 'Business Travel',
        url: '/business',
        submenu: [
            {
                title: 'Global Services',
                url: '/business/global-services',
            },
            {
                title: 'Program Management',
                url: '/business/program-management',
            },
            {
                title: 'Risk Management',
                url: '/business/risk-management',
            },
            {
                title: 'Traveler Experience',
                url: '/business/traveler-experience',
            },
        ],
    },
    {
        title: 'Customers',
        url: '/customers',
        submenu: [
            {
                title: 'Construction & Engineering',
                url: '/customers/construction-engineering',
            },
            {
                title: 'Education',
                url: '/customers/education',
            },
            {
                title: 'Energy/Oil/Gas',
                url: '/customers/energy',
            },
            {
                title: 'Financial Servcices',
                url: '/customers/finance',
            },
            {
                title: 'Government',
                url: '/customers/government',
            },
            {
                title: 'Healthcare',
                url: '/customers/healthcare',
            },
            {
                title: 'Manufacturing',
                url: '/customers/manufacturing',
            },
            {
                title: 'Media & Entertainment',
                url: '/customers/media',
            },
            {
                title: 'Non-Profit',
                url: '/customers/non-profit',
            },
            {
                title: 'Pharmaceutical Industry',
                url: '/customers/pharmaceutical',
            },
            {
                title: 'Professional Services',
                url: '/customers/professional',
            },
            {
                title: 'Technology',
                url: '/customers/technology',
            },
        ],
    },
    {
        title: 'Technology Solutions',
        url: '/technology',
        submenu: [
            {
                title: 'Duty of Care',
                url: '/technology/duty-of-care',
            },
            {
                title: 'Online Booking Tools',
                url: '/technology/online-booking-tools',
            },
            {
                title: 'Reporting',
                url: '/technology/reporting',
            },
            {
                title: 'Unused Ticket Tracking',
                url: '/technology/unused-ticket-tracking',
            },
            {
                title: 'Virtual Payments',
                url: '/technology/virtual-payments',
            },
        ],
    },
    {
        title: 'About Us',
        url: '/about',
    },
];

export default menuItemsData;
