/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    PiBuildings,
} from 'react-icons/pi';
import Hero from '../components/Hero';
import SearchBox from '../components/SearchBox';
import InfoBar from '../components/InfoBar';
import InfoImageBar from '../components/InfoImageBar';
import FeaturedAgency from '../components/FeaturedAgency';
import Button from '../components/elements/Button';
import BreadCrumbs from '../components/elements/BreadCrumbs';


function Technology() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions);
        };
    }, []);

    const [links, setLinks] = useState([]);
    useEffect(() => {
        setLinks([{
            label: 'Home',
            url: '/',
        },
        {
            label: 'Technology Solutions',
        },
        ]);
    }, []);

    function heroImage() {
        let heroProps = { imageHeight: '300px', backgroundPosition: '30%' };
        if (windowWidth > 568) {
            heroProps = { imageHeight: '500px', backgroundPosition: '' };
        }
        return heroProps;
    }
    function goToAnchor(anchor) {
        const loc = document.location.toString().split('#')[0];
        document.location = loc + '#' + anchor;
        return false;
    }
    const backToTop = <div className="mx-4 md:mx-20 text-right -mt-8 "><span className="text-gray-800 hover: hover:text-red-700 cursor-pointer" onClick={() => goToAnchor('topics')}>Back to Topics</span></div>;
    return (
        <>
            <Helmet>
                <title>Technology Solutions - Travel Leaders Business</title>
                <meta name="title" content="Technology Solutions - Travel Leaders Business" />
                <meta name="description" content="Maximize traveler efficiency with our comprehensive travel technology solutions, featuring duty of care, robust reporting, seamless online booking, unused ticket tracking, and secure virtual payment options." />
            </Helmet>
            <Hero
                title="Technology Solutions"
                upperText=""
                lowerText=""
                fileName="/images/hero-technology1.webp"
                height={heroImage().imageHeight}
                fileType="image"
                backgroundPosition={heroImage().backgroundPosition}
            />
            <div className="min-h-screen max-w-screen-xl mx-auto">
                <div className="-mt-4 md:-mt-20 relative left-0 md:mx-20 right-0 z-10 shadow-lg md:shadow-none">
                    <SearchBox />
                </div>
                <div className="my-4">
                    <BreadCrumbs links={links} />
                </div>
                <div className="my-4">
                    <InfoBar
                        iconPosition="top"
                        text={`It goes without saying that today's business traveler expects their world at their fingertips.
                               Travel Leaders makes this possible by seamlessly integrating a technology platform that is flexible, reliable and able to support all aspects of your travel programs life cycle.
                               <div class="mt-4">Our end-to-end technology platform can support your travelers with industry-leading solutions for online booking tools, mobile applications, expense management, quality assurance, detailed reporting and much more.</div>`}
                    />
                </div>
                <div className="my-4" id="topics">
                    <div className="mx-2 my-8 md:mx-20 relative text-center bg-white">
                        <div
                            className="flex  flex-col flex-nowrap relative w-full p2 md:p-8  bg-gray-800 text-white"
                            style={{ background: 'url(\'/images/bg-business.webp\')', backgroundSize: 'cover' }}
                        >
                            <div className="w-32 border-b-2 border-red-700 mb-2 mt-4 mx-4 md:mx-0 md:-mt-2" />
                            <div className="py-2 px-4 md:px-0 md:py-0 w-full text-left">
                                <h3 className="block font-semibold uppercase py-2 tracking-wide">
                                    Click on the topics below to learn more
                                </h3>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center">
                                    <div className="w-full">
                                        <Button
                                            title="Duty of Care"
                                            onClick={() => goToAnchor('duty')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Online Booking Tools"
                                            onClick={() => goToAnchor('online')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full">
                                        <Button
                                            title="Reporting"
                                            onClick={() => goToAnchor('reporting')}
                                            inverted
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center">
                                    <div className="w-full md:w-4/6">
                                        <Button
                                            title="Unused Ticket Tracking"
                                            onClick={() => goToAnchor('unused')}
                                            inverted
                                        />
                                    </div>
                                    <div className="w-full md:w-4/6">
                                        <Button
                                            title="Virtual Payments"
                                            onClick={() => goToAnchor('virtual')}
                                            inverted
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-4" id="duty">
                    <InfoImageBar
                        title="Duty of Care"
                        text="GuardianCare gives you the powerful assistance and innovative technology you need to keep your travelers updated and accounted for worldwide. Powered by our partners at Onsolve, GuardianCare combines reliable pre-trip risk advisories, global alerts, an interactive traveler dashboard and optional integrations with Connect."
                        imageUrl="images/DutyOfCare.webp"
                        imageAlt="Rolling hills"
                        imageAsBG
                        iconPosition="top"
                        btnIcon="suitcaseRolling"
                        btnText="Learn More"
                        btnLink="/technology/duty-of-care"
                        inverted
                    />
                </div>
                {backToTop}
                <div className="my-4" id="online">
                    <InfoImageBar
                        title="Online Booking Tools"
                        text="Travel Leaders currently offer special negotiated rates to our business clients using the leading online booking tools Concur and Deem. Our ability to integrate with these technologies enables our clients to have an on-demand option for securing business travel reservations at any time of day or night."
                        imageUrl="images/OnlineBookingTools.webp"
                        imageAlt="People boarding a plane"
                        imageAsBG
                        iconPosition="top"
                        btnIcon="globe"
                        btnText="Learn More"
                        btnLink="/technology/online-booking-tools"
                    />
                </div>
                {backToTop}
                <div className="my-4" id="reporting">
                    <InfoImageBar
                        title="Reporting"
                        text="Travel Leaders uses Business Travel Intelligence as well as many other online Travel Management Reporting Tools. These tools automatically organize travel data into useful categories, which then becomes useful data extracts for users."
                        imageUrl="images/Reporting.webp"
                        imageAlt="Handing over the car keys to the valet"
                        imageAsBG
                        iconPosition="top"
                        btnIcon="plane"
                        btnText="Learn More"
                        btnLink="/technology/reporting"
                        inverted
                    />
                </div>
                {backToTop}
                <div className="my-4" id="unused">
                    <InfoImageBar
                        title="Unused Ticket Tracking"
                        text="Unused airfare can be an expensive problem. Travel Leaders utilizes a best-in-class application that verifies usage of booked airfare and initiates refunds when allowed. Our software searches itineraries with purchased airfare and pushes unused tickets to our automated refund processing tool. If the airfare is non-refundable, the residual value is calculated and reminders are dropped both in your travel advisor's desktop and the traveler's profile for visibility."
                        imageUrl="images/UnusedTicket.webp"
                        imageAlt="Amalfi Coas"
                        imageAsBG
                        iconPosition="top"
                        btnIcon="globe"
                        btnText="Learn More"
                        btnLink="/technology/unused-ticket-tracking"
                    />
                </div>
                {backToTop}
                <div className="my-4" id="virtual">
                    <InfoImageBar
                        title="Virtual Payments"
                        text="Travel Leaders SecurePay, powered by Conferma, is one of the world's foremost providers of virtual payment technology offering a complete program of solutions and complimentary products in one single platform. Banks, businesses and travel providers can make the most of this cutting-edge technology by selecting Virtual Payments."
                        imageUrl="images/VirtualPayments.webp"
                        imageAlt="Colorful houses along a canal"
                        imageAsBG
                        iconPosition="top"
                        btnIcon="dollar"
                        btnText="Learn More"
                        btnLink="/technology/virtual-payments"
                        inverted
                    />
                    {backToTop}
                </div>
                <FeaturedAgency />
            </div>
        </>
    );
}

export default Technology;
